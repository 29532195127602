import React from "react";
import { useConfig } from "../../context/ConfigContext";

function CustomHtmlComponent({ id }) {
  const ConfigProvider = useConfig();

  return ConfigProvider.config.htmlComponents
    .filter(
      (x) =>
        x._id === id && x.translation.hasOwnProperty(ConfigProvider.config.lan)
    )
    .map((item, key) => (
      <div
        key={key}
        className="w-full"
        dangerouslySetInnerHTML={{
          __html: item.translation[ConfigProvider.config.lan],
        }}
      />
    ));
}

export default CustomHtmlComponent;
