import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDeviceSize } from "../context/DeviceSizeContext";
import { useConfig } from "../context/ConfigContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Requests from "../helpers/Requests";
import Modal from "../components/Common/Modal";
import Alert from "../components/Common/Alert";
import LastWinnersModule from "../components/Common/Casino/LastWinnersModule";
import Login from "./Login";
import {
  PlayIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
  StarIcon,
  HandThumbUpIcon,
  ArrowsPointingOutIcon,
  ComputerDesktopIcon,
  ShareIcon,
} from "@heroicons/react/24/solid";
import RecommendGames from "../components/Common/Casino/RecommendGames";
import ShareModal from "../components/Common/Casino/ShareModal";

function CasinoGame() {
  const ConfigProvider = useConfig();
  const { id } = useParams();

  const DeviceSizeProvider = useDeviceSize();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [id]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      const fetchData = async () => {
        const data = await Requests.postData("/casino/getGameDetail", {
          _id: id,
        });
        return data;
      };

      fetchData()
        .then((response) => {
          const res = response.data;
          if (res.type === "success") {
            setData(res.data);
            if (!res.data.demo && gameMode === 1) {
              setGameMode(0);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          Requests.diagnostics(err, navigate);
          setLoading(false);
        });
    }
  };
  const [gameMode, setGameMode] = useState(0); //0 reel 1 demo
  const [gameSize, setGameSize] = useState(0); //0 normal 1 wide

  const upsertCasinoGameInteractions = (type, rating, del) => {
    if (ConfigProvider.config.user === null) {
      setLoginModal(true);
    } else if (!loading) {
      const fetchData = async () => {
        const data = await Requests.postData(
          "/casino/upsertCasinoGameInteractions",
          {
            _id: id,
            type: type,
            rating: rating,
            delete: del,
          }
        );
        return data;
      };

      fetchData()
        .then((response) => {
          const res = response.data;
          if (res.type === "success") {
            if (type === "fav") {
              setData((prev) => {
                return {
                  ...prev,
                  favorited: !del,
                  favoritesCount: prev.favoritesCount + (!del ? 1 : -1),
                };
              });
            } else if (type === "like") {
              setData((prev) => {
                return {
                  ...prev,
                  liked: !del,
                  likesCount: prev.likesCount + (!del ? 1 : -1),
                };
              });
            } else {
              setTimeout(() => {
                getItems();
              }, 100);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          Requests.diagnostics(err, navigate);
          setLoading(false);
        });
    }
  };
  const [loginModal, setLoginModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);

  const iframeRef = useRef(null);

  const toggleFullscreen = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) {
        // Firefox için
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) {
        // Chrome ve Safari için
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) {
        // IE/Edge için
        iframe.msRequestFullscreen();
      }
    }
  };
  const heartbeatGame = () => {
    if (!loading) {
      setLoading(true);
      const fetchData = async () => {
        const data = await Requests.postData("/casino/heartbeatGame", {
          _id: id,
        });
        return data;
      };

      fetchData()
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  return (
    <>
      <div className="w-full px-4">
        <div
          className={`${
            gameSize === 1 && DeviceSizeProvider.deviceType !== "sm"
              ? "max-w-full"
              : "max-w-7xl"
          } p-2 overflow-hidden mx-auto transition-all duration-500`}
        >
          <div className="w-full py-2">
            <div className="w-full flex justify-between my-2 items-center mb-5">
              <div className="flex items-center rounded-lg h-10">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="btn bg-layout-bg cursor-pointer mr-3 h-8 rounded-lg"
                  type="button"
                >
                  <div className="ml-auto flex items-center justify-center rounded-lg w-8 h-8">
                    <ChevronLeftIcon className="w-6 fill-current" />
                  </div>
                </button>
                <div className="font-bold flex items-center text-lg">
                  {data && data.displayName}
                </div>
              </div>
            </div>

            {DeviceSizeProvider.deviceType === "sm" ? (
              <GameContainerMobile
                id={id}
                data={data}
                setLoginModal={setLoginModal}
                upsertCasinoGameInteractions={upsertCasinoGameInteractions}
                setShareModal={setShareModal}
              />
            ) : (
              <div className="relative bg-layout-bg rounded-xl transition-all duration-300">
                <div className="flex flex-1 h-12 gap-2 pr-2 items-center opacity-100">
                  <div className="flex flex-1 px-2"></div>
                  <div className="flex items-center justify-center ml-auto h-10 rounded-lg bg-page-bg text-page-text p-1 text-sm">
                    {data && data.demo ? (
                      <div
                        className={`px-2 rounded-md h-full flex items-center justify-center cursor-pointer ${
                          gameMode === 1 ? " bg-layout-bg text-white" : ""
                        }`}
                        onClick={() => setGameMode(1)}
                      >
                        <span>{ConfigProvider.getTranslation("FreePlay")}</span>
                      </div>
                    ) : null}
                    <div
                      className={`px-2 rounded-md h-full flex items-center justify-center ${
                        gameMode === 0 ? " bg-layout-bg text-white" : ""
                      } ${data && data.demo ? "cursor-pointer" : ""}`}
                      onClick={(e) =>
                        data && data.demo ? setGameMode(0) : e.preventDefault()
                      }
                    >
                      <span className="font-semibold">
                        {ConfigProvider.getTranslation("ReelPlay")}
                      </span>
                    </div>
                  </div>
                </div>
                <GameContainerDesktop
                  id={id}
                  demo={gameMode === 1}
                  setLoginModal={setLoginModal}
                  iframeRef={iframeRef}
                  heartbeatGame={heartbeatGame}
                />
                <div className="px-4 rounded-xl flex items-end justify-between h-16">
                  <div className="flex items-center jus gap-4 h-full flex-1">
                    <SocialButtons
                      data={data}
                      upsertCasinoGameInteractions={
                        upsertCasinoGameInteractions
                      }
                      setShareModal={setShareModal}
                    />
                  </div>
                  <div className="hidden md:block h-full">
                    <LazyLoadImage
                      alt={ConfigProvider.config.hostnames.name + " Logo"}
                      src={`${process.env.REACT_APP_CDN_ENDPOINT}${ConfigProvider.config.hostnames.logo}`}
                      className="relative top-1/2 -translate-y-1/2 opacity-50 grayscale h-6"
                    />
                  </div>
                  <div className="flex items-center gap-4 h-full flex-1 justify-end">
                    <button
                      className="btn flex items-center justify-center p-0 text-page-text text-sm"
                      type="button"
                      onClick={() => {
                        setGameSize(gameSize === 1 ? 0 : 1);
                      }}
                    >
                      <ComputerDesktopIcon className="w-8 fill-current" />
                    </button>
                    <button
                      className="btn flex items-center justify-center p-0 text-page-text text-sm"
                      type="button"
                      onClick={() => {
                        toggleFullscreen();
                      }}
                    >
                      <ArrowsPointingOutIcon className="w-8 fill-current" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="max-w-7xl p-2 overflow-hidden mx-auto">
          <div className="w-full flex flex-col space-y-2 py-2">
            <RatingContainer
              data={data}
              upsertCasinoGameInteractions={upsertCasinoGameInteractions}
            />

            <RecommendGames game={id} />
            {ConfigProvider.config.prefences.lastWinnersModule && (
              <LastWinnersModule game={id} myBets={true} offset={20} />
            )}
          </div>
        </div>
      </div>

      {loginModal ? (
        <Modal
          callback={() => {
            setLoginModal(false);
          }}
        >
          <Login
            callback={() => {
              setLoginModal(false);
            }}
          />
        </Modal>
      ) : null}
      {shareModal ? (
        <Modal
          callback={() => {
            setShareModal(false);
          }}
          showLogo={false}
        >
          <ShareModal
            setOpen={shareModal}
            messagaTitle={
              data ? data.displayName : ConfigProvider.config.hostnames.name
            }
            content={ConfigProvider.getTranslation("Play")}
            path={`/casinoGame/${id}`}
          />
        </Modal>
      ) : null}
    </>
  );
}
function SocialButtons({ data, upsertCasinoGameInteractions, setShareModal }) {
  const ConfigProvider = useConfig();
  return (
    <>
      <button
        type="button"
        className={`btn flex items-center justify-center p-0 text-sm ${
          data && data.favorited ? "text-menu-bg" : "text-page-text"
        }`}
        onClick={() =>
          upsertCasinoGameInteractions("fav", 5, data && data.favorited)
        }
      >
        <StarIcon className="w-6 fill-current" />
        <span className="hidden md:block ml-1">
          {data && data.favoritesCount}
        </span>
      </button>
      {ConfigProvider.config.prefences.casinoLike && (
        <button
          type="button"
          className={`btn flex items-center justify-center p-0 text-sm ${
            data && data.liked ? "text-menu-bg" : "text-page-text"
          }`}
          onClick={() =>
            upsertCasinoGameInteractions("like", 5, data && data.liked)
          }
        >
          <HandThumbUpIcon className="w-6 fill-current" />
          <span className="hidden md:block ml-1">
            {data && data.likesCount}
          </span>
        </button>
      )}
      {ConfigProvider.config.prefences.casinoShare && (
        <button
          type="button"
          className={`btn flex items-center justify-center p-0 text-page-text text-sm`}
          onClick={() => {
            setShareModal(true);
          }}
        >
          <ShareIcon className="w-6 fill-current" />
        </button>
      )}
    </>
  );
}
function RenderStars({
  count,
  filled,
  max = 5,
  hover = false,
  update = undefined,
}) {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return Array.from({ length: max }, (_, i) => (
    <span
      key={i}
      className="leading-none"
      onMouseEnter={() => hover && setHoveredIndex(i)}
      onMouseLeave={() =>
        hover &&
        setTimeout(() => {
          setHoveredIndex(null);
        }, 500)
      }
      onClick={() => {
        update && hover && update(i + 1);
      }}
    >
      <StarIcon
        className={`w-[1.125rem] h-[1.125rem] ${
          hover ? "cursor-pointer" : ""
        } ${
          hover && hoveredIndex !== null && i <= hoveredIndex
            ? "fill-menu-bg"
            : hoveredIndex === null && i < count
            ? filled
            : "fill-page-text"
        }`}
      />
    </span>
  ));
}
function RatingContainer({ data, upsertCasinoGameInteractions }) {
  const ConfigProvider = useConfig();
  const [open, setOpen] = useState(false);

  return (
    <>
      <section className="mt-4 mb-2 md:mt-5 md:mb-3 py-4 px-2 flex flex-col space-y-4 outline-none bg-layout-bg rounded-xl md:py-5 md:px-3 relative">
        <div className="flex justify-between items-start">
          {data && (
            <div className="flex flex-col">
              <span className="font-bold text-lg">{data.displayName}</span>
              <div className="flex items-center gap-1">
                <span className="text-page-text font-semibold text-sm">
                  {ConfigProvider.getTranslation("With")}
                </span>
                <Link
                  to={`/casino/provider/${encodeURIComponent(
                    data.providerName
                  )}/${data.provider}`}
                  className="text-menu-bg font-semibold text-sm inactive"
                >
                  {data.providerName}
                </Link>
              </div>
            </div>
          )}
          {ConfigProvider.config.prefences.casinoRating && (
            <button
              type="button"
              aria-expanded={open}
              onClick={() => setOpen(!open)}
              className="inline-flex items-center justify-center font-bold py-2 disabled:pointer-events-none disabled:opacity-40 w-8 h-8 rounded-lg bg-page-bg  hover:bg-page-text/5"
            >
              <ChevronDownIcon
                className={`w-6 fill-current transition-all duration-500 ${
                  open ? "rotate-180" : ""
                }`}
              />
            </button>
          )}
        </div>

        <div
          className={`overflow-hidden transition-all duration-700 ease-in-out ${
            open ? "max-h-screen" : "max-h-0"
          }`}
        >
          <div className="p-3 flex flex-col md:flex-row gap-2 bg-page-bg rounded-xl">
            <div className="flex gap-1 items-center grow flex-1">
              <p className="mb-4 mx-10">
                <span className="text-3xl font-bold">
                  {Number(
                    data ? (data.averageRating > 0 ? data.averageRating : 5) : 5
                  ).toFixed(1)}
                </span>
              </p>
              <div className="flex flex-col justify-start">
                <div className="flex gap-1">
                  {data && (
                    <RenderStars
                      count={parseInt(data ? data.averageRating : 0)}
                      filled="fill-menu-bg"
                    />
                  )}
                </div>
                <span className="text-page-text">
                  {ConfigProvider.getTranslation("OutOf5")}
                </span>
              </div>
            </div>

            <div className="flex flex-col justify-between flex-1">
              {data &&
                [5, 4, 3, 2, 1].map((item) => (
                  <ReviewItem key={item} data={data} item={item} />
                ))}
            </div>
          </div>
          <div className="w-full flex gap-1.5 mt-1.5 rounded-xl">
            <div className="w-1/2 py-4 px-5 flex flex-col md:flex-row items-center gap-1 bg-page-bg justify-between rounded-lg">
              <p className="text-sm">
                {ConfigProvider.getTranslation("RateThisGame")}
              </p>
              <div className="flex gap-1">
                {data && (
                  <RenderStars
                    count={data.rating}
                    filled="fill-menu-bg"
                    max={5}
                    hover={true}
                    update={(val) => {
                      upsertCasinoGameInteractions("rating", val, false);
                    }}
                  />
                )}
              </div>
            </div>
            <div className="w-1/2 py-4 px-5 flex flex-col md:flex-row items-center gap-1 bg-page-bg rounded-lg justify-center">
              <p className="h-6 text-sm">
                {`${
                  data ? data.reviewsCount : 0
                } ${ConfigProvider.getTranslation("Ratings")}`}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
function ReviewItem({ item, data }) {
  const selData = data.reviews.find((x) => x.rating === item);
  const percent = selData ? (selData.count * 100) / data.reviewsCount : 0;

  return (
    <div className="flex items-center">
      <div className="flex gap-0 mr-1 min-w-[5.6rem]">
        <RenderStars count={item} filled="fill-page-text" max={item} />
      </div>
      <div
        role="progressbar"
        aria-valuenow="10"
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuetext="%0"
        className="grow"
      >
        <div className="relative w-full bg-layout-bg overflow-hidden rounded-xl h-2">
          <div
            className="h-full bg-menu-bg transition-all rounded-xl"
            style={{ width: `${+percent.toFixed(2)}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

function GameContainerDesktop({
  id,
  demo,
  setLoginModal,
  iframeRef,
  heartbeatGame,
}) {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();

  const [messages, setMessages] = useState([]);
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (ConfigProvider.config.user !== null) getItems();
    // eslint-disable-next-line
  }, [demo, id]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setLink(null);
      setMessages([]);
      const fetchData = async () => {
        const data = await Requests.postData("/casino/launch", {
          _id: id,
          demo: demo,
        });
        return data;
      };

      fetchData()
        .then((response) => {
          const res = response.data;
          if (res.type !== "success") {
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            window.scrollTo(0, 0);
          } else {
            if (res.data.type === 0) {
              setLink(res.data.content);
            } else {
              setLink(
                `${process.env.REACT_APP_API_ENDPOINT}/casino/launchHTML?code=${res.data.content}`
              );
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          Requests.diagnostics(err, navigate);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (ConfigProvider.config.user !== null) {
      const interval = setInterval(() => {
        heartbeatGame();
      }, 60000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-page-bg relative">
      {messages.length > 0 && (
        <div className="py-2 px-4">
          <Alert messages={messages} setMessages={setMessages} />
        </div>
      )}
      <div className="w-full relative overflow-hidden after:content-[''] after:block after:w-full after:aspect-video">
        <div className="absolute w-full h-full flex items-center ">
          <LazyLoadImage
            alt=""
            src={`${process.env.REACT_APP_CDN_ENDPOINT}${ConfigProvider.config.hostnames.logo}`}
            className="w-full opacity-10 -rotate-12 scale-125 animate-growShrink"
          />
        </div>
        {ConfigProvider.config.user === null ? (
          <div className="absolute w-full h-full flex items-center justify-center">
            <div className="sm:w-[30rem] rounded-t-3xl sm:rounded-3xl overflow-hidden bg-layout-bg p-8">
              <div className="w-full h-14 flex items-center justify-between">
                <p className="text-base grow text-center font-bold">
                  {ConfigProvider.getTranslation(
                    "SignUpAndLoginForExclusiveBigWins"
                  )}
                  !
                </p>
              </div>
              <div className="text-[10px] text-page-text sm:text-xs sm:mb-[1.69rem] sm:mt-[1.19rem] w-full text-center">
                {ConfigProvider.getTranslation(
                  "WhereFunExcitementAndRewardsMeetDontMissYourExclusiveOpportunities"
                )}
                <br />
                {ConfigProvider.getTranslation(
                  "JoinNowAndStepIntoBigRewardsWithTheMostPopularGames"
                )}
                !
              </div>

              <div className="flex items-center- justify-center w-full mt-6">
                <button
                  className="w-[16rem] h-10 flex items-center justify-center rounded-lg text-sm px-2 bg-menu-bg text-left-title-text hover:brightness-90 font-bold active:translate-y-0.5 duration-300"
                  type="button"
                  onClick={() => {
                    setLoginModal(true);
                  }}
                >
                  <PlayIcon className="w-8 mr-2" />
                  {ConfigProvider.getTranslation("Play")}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {messages.length > 0 ? (
              <div className="absolute w-full h-full flex items-center justify-center">
                <div className="sm:w-[30rem] rounded-t-3xl sm:rounded-3xl overflow-hidden bg-layout-bg p-8">
                  <div className="w-full h-14 flex items-center justify-between">
                    <p className="text-base grow text-center font-bold">
                      {ConfigProvider.getTranslation(
                        "SorryTheGameCannotBeOpenedRightNow"
                      )}
                    </p>
                  </div>
                  <div className="text-base font-bold text-page-text sm:mb-[1.69rem] sm:mt-[1.19rem] w-full text-center">
                    {messages.map((item, index) => (
                      <div key={index} className="w-full clear-both leading-10">
                        {item.text}
                      </div>
                    ))}
                    <div className="w-full clear-both leading-10">
                      {ConfigProvider.getTranslation("PleaseTryAgainLater")}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {link && (
                  <iframe
                    className="absolute left-0 top-0 w-full h-full border-none"
                    allowFullScreen
                    ref={iframeRef}
                    src={link}
                    title="Game"
                  ></iframe>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function GameContainerMobile({
  id,
  data,
  setLoginModal,
  upsertCasinoGameInteractions,
  setShareModal,
}) {
  const ConfigProvider = useConfig();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();
  const getItems = (demo) => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const fetchData = async () => {
        const data = await Requests.postData("/casino/launch", {
          _id: id,
          demo: demo,
        });
        return data;
      };

      fetchData()
        .then((response) => {
          const res = response.data;
          if (res.type !== "success") {
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            window.scrollTo(0, 0);
          } else {
            if (res.data.type === 0) {
              window.location.href = res.data.content;
            } else {
              window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/casino/launchHTML?code=${res.data.content}`;
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          Requests.diagnostics(err, navigate);
          setLoading(false);
        });
    }
  };
  return (
    <div className="bg-layout-border rounded-xl">
      {messages.length > 0 && (
        <div className="py-2 px-4">
          <Alert messages={messages} setMessages={setMessages} />
        </div>
      )}
      <div className="flex items-center gap-3 p-4 bg-layout-bg rounded-tl-xl rounded-tr-xl">
        <div>
          {data && (
            <LazyLoadImage
              src={
                data.imageUrl.startsWith("casino/")
                  ? `${process.env.REACT_APP_CDN_ENDPOINT}/upload/img/${data.imageUrl}`
                  : data.imageUrl
              }
              className="w-32 h-24 rounded-md transition duration-300"
              style={{
                filter: imageLoaded ? "none" : "blur(10px)",
              }}
              onLoad={() => {
                setTimeout(() => {
                  setImageLoaded(true);
                }, 100);
              }}
            />
          )}
        </div>
        <div className="flex flex-col gap-2 h-full flex-1">
          <div className="flex flex-col justify-center items-left gap-2 ">
            <div className="text-sm font-bold text-center mb-4">
              {ConfigProvider.getTranslation("PlayWithBalance")}
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              className="h-14 flex items-center justify-center rounded-lg text-lg px-6 bg-menu-bg text-left-title-text hover:brightness-90 font-bold active:translate-y-0.5 duration-300"
              type="button"
              onClick={() => {
                ConfigProvider.config.user === null
                  ? setLoginModal(true)
                  : getItems(false);
              }}
            >
              <PlayIcon className="w-8 mr-2" />
              {ConfigProvider.getTranslation("Play")}
            </button>
          </div>
          {data && !data.demo ? (
            <>
              <p className="text-sm text-center mt-2">
                <span className="text-page-text">
                  {ConfigProvider.getTranslation("Or")}
                </span>
              </p>
              <p className="text-sm text-center">
                <span
                  className="text-menu-bg ml-1"
                  onClick={() => {
                    ConfigProvider.config.user === null
                      ? setLoginModal(true)
                      : getItems(true);
                  }}
                >
                  {ConfigProvider.getTranslation("FreePlay")}
                </span>
              </p>
            </>
          ) : null}
        </div>
      </div>
      <div className="px-4 flex items-end justify-between border-t border-page-bg/90 h-12">
        <div className="flex items-center gap-4">&nbsp;</div>
        <div className="flex items-center justify-center gap-4 h-full">
          <SocialButtons
            data={data}
            upsertCasinoGameInteractions={upsertCasinoGameInteractions}
            setShareModal={setShareModal}
          />
        </div>
      </div>
    </div>
  );
}
export default CasinoGame;
