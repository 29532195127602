import { useConfig } from "../../context/ConfigContext";
import "../../assets/css/topWinners.css";
import React from "react";
import { SvgLoader } from "react-svgmt";
import { Link } from "react-router-dom";

function CardsComponent() {
  const ConfigProvider = useConfig();
  const data = ConfigProvider.config.homeCards;
  const cdnEndpoint = process.env.REACT_APP_CDN_ENDPOINT;
  const theme = "dark";
  return data.length === 0 ? (
    <></>
  ) : (
    <div className="grid grid-cols-4 lg:grid-cols-10 gap-2 mt-4">
      {data.map((item, index) => (
        <Link
          key={index}
          className={`btn relative flex-1 rounded-xl bg-layout-bg text-page-text font-bold overflow-hidden center hover:brightness-105 ${
            item.size === "large"
              ? "col-span-2 lg:col-span-3 row-span-2 h-32 sm:h-[252px] p-1.5 sm:px-3 sm:py-5"
              : "lg:col-span-2 h-24 sm:h-[120px] p-2"
          }`}
          to={item.link}
          style={{
            backgroundImage: item.bgColors
              ? item.bgColors.hasOwnProperty(theme)
                ? item.bgColors[theme]
                : ""
              : "",
          }}
        >
          <img
            className={`${
              item.size === "large"
                ? "absolute right-0 top-0 h-[120%] sm:h-full"
                : "absolute left-[50%] top-[5%] h-[70%] -translate-x-[50%] lg:top-0 lg:h-full lg:-translate-x-[10%]"
            }`}
            srcSet={`${cdnEndpoint}/upload/img/${item.image}`}
            alt=""
          />

          <div
            className={`relative z-10 flex h-full flex-col flex-auto ${
              item.size === "small" ? "hidden lg:block" : ""
            }`}
          >
            <div className="flex items-center">
              {item.nameIcon && item.nameIcon.length > 0 ? (
                <SvgLoader
                  svgXML={item.nameIcon}
                  className={`flex-none fill-current ${
                    item.size === "large" ? "h-8 w-8" : "h-6 w-6"
                  }`}
                />
              ) : null}
              <span
                className={`${
                  item.size === "large"
                    ? "text-sm ml-0.5 sm:text-2xl"
                    : "text-xs ml-0.5 lg:text-lg"
                } text-white uppercase`}
              >
                {ConfigProvider.translateItem({
                  name: "",
                  translation: item.title,
                })}
              </span>
            </div>
            {item.size === "large" ? (
              <div className="hidden font-semibold mb-1.5 mt-auto max-w-60 text-left lg:block text-page-text">
                {ConfigProvider.translateItem({
                  name: "",
                  translation: item.subTitle,
                })}
              </div>
            ) : null}
          </div>

          {item.size === "large" ? null : (
            <div className="block absolute w-full left-0 text-center bottom-2 lg:hidden">
              <span className={`text-sm text-layout-text uppercase`}>
                {ConfigProvider.translateItem({
                  name: "",
                  translation: item.title,
                })}
              </span>
            </div>
          )}
        </Link>
      ))}
    </div>
  );
}

export default CardsComponent;
