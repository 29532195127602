import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Outlet,
  useLocation,
  useParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import Home from "./pages/Home";
import Casino from "./pages/Casino";
import Blog from "./pages/Blog";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterFull from "./pages/RegisterFull";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import Profile from "./pages/Profile";
import Deposit from "./pages/Deposit";
import DepositForm from "./pages/DepositForm";
import Withdrawal from "./pages/Withdrawal";
import WithdrawalForm from "./pages/WithdrawalForm";
import PaymentTransactionHistory from "./pages/PaymentTransactionHistory";
import TransactionHistory from "./pages/TransactionHistory";
import CasinoGame from "./pages/CasinoGame";
import Error from "./pages/Error";
import { useDeviceSize } from "./context/DeviceSizeContext";
import { useConfig } from "./context/ConfigContext";
import { useSocket } from "./context/SocketContext";
import { Header, HeaderLeftSide } from "./components/Common/Header/Header";
import TimeOutModal from "./components/Common/TimeOutModal";
import FooterNav from "./components/Common/Footer/FooterNav";
import Footer from "./components/Common/Footer/Footer";
import Promotions from "./pages/Promotions";
import CMSPage from "./pages/CMSPage";
import SidebarFavorites from "./components/Common/Header/SidebarFavorites";
import Limits from "./pages/Limits";
import ValidateEmail from "./pages/ValidateEmail";
import PasswordRecovery from "./pages/PasswordRecovery";
import Marquee from "./components/Common/Marquee";
import JackpotContainer from "./components/Common/JackpotContainer";
import Bonuses from "./pages/Bonuses";
import BonusHistory from "./pages/BonusHistory";
import { useFavorites } from "./context/FavoritesContext";
import Messages from "./pages/Messages";
import { useTimezone } from "./context/TimezoneContext";
import CasinoProviders from "./pages/CasinoProviders";
import CasinoListMini from "./pages/CasinoListMini";

function App() {
  const TimezoneProvider = useTimezone();
  const SocketProvider = useSocket();
  const ConfigProvider = useConfig();
  const { userQueue, clearUserMessages, timeQueue, clearTimeMessages } =
    SocketProvider;

  const [userId, setUserId] = useState("");
  useEffect(() => {
    if (ConfigProvider.config.user && ConfigProvider.config.user !== null) {
      if (ConfigProvider.config.user._id !== userId) {
        setUserId(ConfigProvider.config.user._id);
        SocketProvider.subscribe(["user-" + ConfigProvider.config.user._id]);
        SocketProvider.defineUserId(ConfigProvider.config.user._id);
      }
    } else if (userId !== "") {
      SocketProvider.unsubscribe(["user-" + userId]);
      setUserId("");
      SocketProvider.defineUserId("");
    }
    // eslint-disable-next-line
  }, [ConfigProvider.config.user, userId]);

  useEffect(() => {
    if (userQueue.length > 0) {
      const readMessages = userQueue;
      readMessages.forEach((item) => {
        const message = JSON.parse(item.message);
        if (
          ConfigProvider.config.user !== null &&
          ConfigProvider.config.user._id === message._id
        ) {
          if (item.cmd === "update") {
            if (message.hasOwnProperty("balance")) {
              ConfigProvider.updateBalance(message.balance, message.strBalance);
            }
            if (message.hasOwnProperty("jackpotPrize")) {
              ConfigProvider.updateJackpotPrize(message.jackpotPrize);
            }
            if (message.hasOwnProperty("userSessionBonuses")) {
              if (
                message.userSessionBonuses &&
                message.userSessionBonuses.hasOwnProperty("remove")
              ) {
              } else if (
                message.userSessionBonuses &&
                message.userSessionBonuses.hasOwnProperty("balance")
              ) {
                ConfigProvider.updateBonusBalance(
                  message.userSessionBonuses._id,
                  message.userSessionBonuses.balance
                );
              }
            }
            if (message.hasOwnProperty("unreadMessages")) {
              ConfigProvider.updateUnreadMessages(message.unreadMessages);
            }
            if (message.hasOwnProperty("unreadNotifications")) {
              ConfigProvider.updateUnreadNotifications(
                message.unreadNotifications
              );
            }
          }
        }
      });
      clearUserMessages(readMessages.length);
    }
    // eslint-disable-next-line
  }, [userQueue, clearUserMessages]);
  useEffect(() => {
    if (timeQueue.length > 0) {
      const readMessages = timeQueue;
      readMessages.forEach((item) => {
        TimezoneProvider.setTime(new Date(item));
      });
      clearTimeMessages(readMessages.length);
    }
    // eslint-disable-next-line
  }, [timeQueue, clearTimeMessages]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route
          path="casino/recommend/:rgame?"
          element={<CasinoListMini recommend={true} />}
        />
        <Route
          path="casino/provider/:pName/:provider"
          element={<CasinoListMini />}
        />
        <Route path="casino/category/show/:title/:cid" element={<Casino1 />} />
        <Route path="casino/category/:path/:cid" element={<Casino2 />} />
        <Route path="casino/providers" element={<CasinoProviders />} />
        <Route path="casino/:path/:cid?" element={<Casino2 />} />
        <Route path="casinoGame/:id" element={<CasinoGame />} />
        <Route path="blog" element={<Blog />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="registerFull" element={<RegisterFull />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="changePassword" element={<ChangePassword />} />
        <Route path="profile" element={<Profile />} />
        <Route path="deposit" element={<Deposit />} />
        <Route path="depositForm/:pm" element={<DepositForm />} />
        <Route path="withdrawal" element={<Withdrawal />} />
        <Route path="withdrawalForm/:pm" element={<WithdrawalForm />} />
        <Route
          path="paymentTransactionHistory"
          element={<PaymentTransactionHistory />}
        />
        <Route path="transactionHistory" element={<TransactionHistory />} />
        <Route path="promotions/:_id?" element={<Promotions />} />
        <Route path="cms/:path" element={<CMSPage />} />
        <Route path="limits" element={<Limits />} />
        <Route path="validateEmail" element={<ValidateEmail />} />
        <Route path="passwordRecovery" element={<PasswordRecovery />} />
        <Route path="bonuses" element={<Bonuses />} />
        <Route path="bonusHistory" element={<BonusHistory />} />
        <Route path="messages" element={<Messages />} />
      </Route>

      <Route path="error/:code/:text" element={<Error />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}
function Casino1() {
  const { title, cid } = useParams();
  return <Casino title={title} cid={cid} />;
}
function Casino2() {
  const { path, cid } = useParams();
  return <Casino path={path} cid={cid} />;
}

function Layout() {
  const DeviceSizeProvider = useDeviceSize();
  const ConfigProvider = useConfig();
  const conf = ConfigProvider.config;
  let location = useLocation();
  const SocketProvider = useSocket();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [favoritesSidebarOpen, setFavoritesSidebarOpen] = useState(false);

  useEffect(() => {
    if (mobileMenuOpen | favoritesSidebarOpen) {
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.paddingRight = "0px";
    } else {
      document.documentElement.style.overflow = "auto";
      document.documentElement.style.paddingRight = "0px";
    }
    if (mobileMenuOpen | favoritesSidebarOpen) {
      setShowAnnouncements(false);
    } else {
      setTimeout(() => {
        setShowAnnouncements(true);
      }, 1000);
    }
  }, [mobileMenuOpen, favoritesSidebarOpen]);
  useEffect(() => {
    try {
      // eslint-disable-next-line
      eval("Comm100API&&Comm100API.set('livechat.button.isVisible', true)");
    } catch (e) {}
  }, []);

  const [showAnnouncements, setShowAnnouncements] = useState(true);

  const FavoritesProvider = useFavorites();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const btag = searchParams.get("btag");
    if (
      FavoritesProvider &&
      btag &&
      btag.length > 0 &&
      FavoritesProvider.btag !== btag
    ) {
      FavoritesProvider.setBtag(btag);
    }
    // eslint-disable-next-line
  }, [window.location.search, FavoritesProvider]);

  return (
    <>
      <Helmet>
        <title>{conf.hostnames.name}</title>
        <link rel="icon" href={conf.hostnames.favicon} />
      </Helmet>

      {conf.announcements &&
      showAnnouncements &&
      conf.announcements.length > 0 ? (
        <div className="w-full">
          <Marquee />
        </div>
      ) : null}

      <div className="bg-page-bg flex">
        <HeaderLeftSide />
        <div className="grow flex flex-col min-h-dvh content-stretch border-amber-950 overflow-auto">
          <div className="flex grow flex-col content-stretch">
            <Header
              setMobileMenuOpen={setMobileMenuOpen}
              setFavoritesSidebarOpen={setFavoritesSidebarOpen}
            />
            <main className="grow bg-page-bg">
              <div className="mx-auto md:container">
                <Outlet />
              </div>
            </main>

            {(location.pathname === "/") |
            location.pathname.startsWith("/casino") |
            location.pathname.startsWith("/cms/") ? (
              <Footer />
            ) : null}
          </div>
          {SocketProvider.error && <TimeOutModal />}
          {DeviceSizeProvider.deviceType === "sm" ? (
            <FooterNav
              mobileMenuOpen={mobileMenuOpen}
              setMobileMenuOpen={setMobileMenuOpen}
              setFavoritesSidebarOpen={setFavoritesSidebarOpen}
            />
          ) : null}
        </div>
      </div>
      <SidebarFavorites
        favoritesSidebarOpen={favoritesSidebarOpen}
        setFavoritesSidebarOpen={setFavoritesSidebarOpen}
      />

      {ConfigProvider.config.user && ConfigProvider.config.user.jackpotPrize ? (
        <JackpotContainer />
      ) : null}
    </>
  );
}

export default App;
