import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { PlayIcon } from "@heroicons/react/24/solid";
import { LazyLoadImage } from "react-lazy-load-image-component";

function CasinoGameItem({ item }) {
  const [showPlayIcon, setShowPlayIcon] = useState("");
  const cdnEndpoint = process.env.REACT_APP_CDN_ENDPOINT;

  return (
    <div className="w-full">
      <div
        className="group transition duration-1000 rounded-t-lg overflow-hidden bg-layout-bg w-full relative"
        onClick={() => {
          setShowPlayIcon(item.game);
        }}
        onMouseEnter={() => {
          setShowPlayIcon(item.game);
        }}
        onMouseLeave={() => {
          setShowPlayIcon("");
        }}
      >
        {showPlayIcon === item.game && (
          <div className="backdrop-blur-sm absolute w-full h-full flex flex-col duration-200 translation z-10">
            <ActiveUsers item={item} />
            <div className="flex flex-1 flex-col space-y-2 items-center justify-center ">
              <div className="flex">
                <Link
                  to={"/casinoGame/" + item.game}
                  className="inline-flex transition w-auto rounded-lg bg-gradient-to-t from-gradient-gold-a to-gradient-gold-b font-semibold text-xs items-center cursor-pointer px-4 py-2 text-white "
                >
                  <PlayIcon className="w-6 h-6 mr-1" /> Play
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className="w-full relative">
          <ActiveUsers item={item} />
          {item.jackpot ? (
            <div className="bg-jackpot"></div>
          ) : item.freespin ? (
            <div className="bg-freespin"></div>
          ) : null}
          <LazyLoadImage
            src={
              item.imageUrl.startsWith("casino/")
                ? `${cdnEndpoint}/upload/img/${item.imageUrl}`
                : item.imageUrl
            }
            className={
              "w-full aspect-[16/11] transition " +
              (showPlayIcon === item.game ? "scale-150" : "")
            }
          />
        </div>
      </div>
      <div className="bg-layout-bg rounded-b-lg mt-2 text-color-1 px-4 py-2 text-sm font-semibold">
        <div className="w-full truncate">{item.displayName}</div>
        <div className="clear-none w-full h-1"></div>
        <div className="w-full text-xs text-menu-bg truncate">
          {item.providerName}
        </div>
      </div>
    </div>
  );
}
function ActiveUsers({ item }) {
  return (
    <div className="absolute flex h-5 items-center rounded-xl right-1.5 top-1.5 bg-black/20 px-1.5 backdrop-blur-lg">
      <svg className="mr-1 w-3 fill-white" viewBox="0 0 24 24">
        <path
          className="opacity-50"
          d="M12 14a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6z"
        ></path>
        <path d="M15.7 12.713a5.975 5.975 0 0 1-7.405 0 9.992 9.992 0 0 0-6.23 8.179 1 1 0 0 0 .886 1.102L20.94 22a1 1 0 0 0 .995-1.108 9.995 9.995 0 0 0-6.233-8.179z"></path>
      </svg>
      <span className="font-semibold text-xs text-[white]">
        {item.activeUsers}
      </span>
    </div>
  );
}
export default CasinoGameItem;
