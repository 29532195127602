import React from "react";
import { useConfig } from "../../../context/ConfigContext";

import facebookImg from "../../../assets/img/share/facebook.png";
import lineImg from "../../../assets/img/share/line.png";
import linkedinImg from "../../../assets/img/share/linkedin.png";
import okImg from "../../../assets/img/share/ok.png";
import skypeImg from "../../../assets/img/share/skype.png";
import telegramImg from "../../../assets/img/share/telegram.png";
import twitterImg from "../../../assets/img/share/twitter.png";
import vkImg from "../../../assets/img/share/vk.png";
import whatsappImg from "../../../assets/img/share/whatsapp.png";

function ShareModal({ messagaTitle = "", content = "", path = "" }) {
  const ConfigProvider = useConfig();
  const title = encodeURIComponent(messagaTitle),
    url = encodeURIComponent(`${window.location.origin}${path}`),
    text = encodeURIComponent(content);

  return (
    <>
      <div className="w-full h-14 flex items-center justify-between">
        <p className="text-base grow text-center font-extrabold">
          {ConfigProvider.getTranslation("Share")}
        </p>
      </div>
      <div className="h-full overflow-y-auto">
        <div className="flex justify-center flex-wrap gap-4">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.facebook.com/sharer.php?title=${title}&text=${text}&u=${url}`}
          >
            <img
              className="w-[2.5rem] h-[2.5rem] mr-[1rem] mb-[1.25rem]"
              srcSet={facebookImg}
              alt="Facebook"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://twitter.com/share?title=${title}&text=${text}&url=${url}`}
          >
            <img
              className="w-[2.5rem] h-[2.5rem] mr-[1rem] mb-[1.25rem]"
              srcSet={twitterImg}
              alt="Twitter"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://t.me/share?title=${title}&text=${text}&url=${url}`}
          >
            <img
              className="w-[2.5rem] h-[2.5rem] mr-[1rem] mb-[1.25rem]"
              srcSet={telegramImg}
              alt="Telegram"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`http://vk.com/share.php?url=${url}&title=${title}&text=${text}`}
          >
            <img
              className="w-[2.5rem] h-[2.5rem] mr-[1rem] mb-[1.25rem]"
              srcSet={vkImg}
              alt="VK"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://lineit.line.me/share/ui?url=${url}&title=${title}&text=${text}`}
          >
            <img
              className="w-[2.5rem] h-[2.5rem] mr-[1rem] mb-[1.25rem]"
              srcSet={lineImg}
              alt="Line"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://web.skype.com/share?url=${url}&title=${title}&text=${text}`}
          >
            <img
              className="w-[2.5rem] h-[2.5rem] mr-[1rem] mb-[1.25rem]"
              srcSet={skypeImg}
              alt="Skype"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://connect.ok.ru/offer?url=${url}&title=${title}`}
          >
            <img
              className="w-[2.5rem] h-[2.5rem] mr-[1rem] mb-[1.25rem]"
              srcSet={okImg}
              alt="OK.RU"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
          >
            <img
              className="w-[2.5rem] h-[2.5rem] mr-[1rem] mb-[1.25rem]"
              srcSet={linkedinImg}
              alt="LinkedIn"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://api.whatsapp.com/send?url=${url}&title=${title}&text=${text}`}
          >
            <img
              className="w-[2.5rem] h-[2.5rem] mr-[1rem] mb-[1.25rem]"
              srcSet={whatsappImg}
              alt="Whatsapp"
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default ShareModal;
