import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import { Link, useNavigate } from "react-router-dom";
import {
  ArrowRightCircleIcon,
  KeyIcon,
  UserIcon,
  AtSymbolIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import Alert from "../components/Common/Alert";
import CountryDropdown from "../components/Common/FormElements/CountryDropdown";
import CurrencyDropdown from "../components/Common/FormElements/CurrencyDropdown";
import Requests from "../helpers/Requests";
import GenderRadio from "../components/Common/FormElements/GenderRadio";
import { classNames } from "../helpers/Helpers";
import { useFavorites } from "../context/FavoritesContext";

function RegisterFull() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  useEffect(() => {
    if (ConfigProvider.config.user || !ConfigProvider.config.allowRegister) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    gender: "M",
    name: "",
    sname: "",
    dateOfBirth: "",
    nationality: "",
    countryOfBirth: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    email: "",
    uname: "",
    password: "",
    password2: "",
    currency: "",
    identity: "",
    phone: "",
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      if (name === "phone") {
        const regex = /^[+\d- ]*$/;
        if (regex.test(value) && value.length <= 15) {
          setFormData({ ...formData, [name]: value.split(" ").join("") });
        }
      } else {
        setFormData({ ...formData, [name]: value });
      }
    }
  };
  const [messages, setMessages] = useState([]);
  const FavoritesProvider = useFavorites();
  const updateForm = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages([]);
      let requiredFieldErros = [];
      if (formData.gender.length === 0) {
        requiredFieldErros.push(ConfigProvider.getTranslation("Gender"));
      }
      // if (formData.nationality.length === 0) {
      //   requiredFieldErros.push(ConfigProvider.getTranslation("Nationality"));
      // }
      // if (formData.countryOfBirth.length === 0) {
      //   requiredFieldErros.push(
      //     ConfigProvider.getTranslation("CountryOfBirth")
      //   );
      // }
      if (formData.country.length === 0) {
        requiredFieldErros.push(ConfigProvider.getTranslation("Country"));
      }
      if (formData.currency.length === 0) {
        requiredFieldErros.push(ConfigProvider.getTranslation("Currency"));
      }
      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
        window.scrollTo(0, 0);
        setLoading(false);
      } else {
        let postData = { ...formData };
        postData.btag = FavoritesProvider.btag;
        Requests.postData("/user/fullRegister", postData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              window.scrollTo(0, 0);
            } else {
              setMessages([
                {
                  type: "success",
                  text: ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);
              if (res.message === "") {
                setTimeout(() => {
                  window.location.href = "/";
                }, 2000);
              } else {
                setTimeout(() => {
                  setShowVerify(true);
                }, 2000);
              }
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const tabs = [
    {
      name: ConfigProvider.getTranslation("Register.MiniInfoForm"),
      href: "/register",
      current: false,
    },
    {
      name: ConfigProvider.getTranslation("Register.FullInfoForm"),
      href: "/registerFull",
      current: true,
    },
  ];

  const [showPassword, setShowPassword] = useState(false);
  const [showVerify, setShowVerify] = useState(false);

  useEffect(() => {
    getBtag();
    // eslint-disable-next-line
  }, []);
  const [btag, setBtag] = useState("");
  const getBtag = () => {
    if (!loading) {
      setLoading(true);
      const fetchData = async () => {
        const data = await Requests.postData("/user/getBtag", {});
        return data;
      };

      fetchData()
        .then((response) => {
          const res = response.data;
          if (res.type === "success") {
            setBtag(res.data);
            if (res.data === "" && FavoritesProvider.btag !== "") {
              setBtag(FavoritesProvider.btag);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="flex flex-col items-center justify-center py-3 md:py-10 px-3 z-10">
      <div className="flex flex-col w-full max-w-screen-xl">
        <div className="flex items-center justify-center gap-4 text-xl from-home-from-5 to-home-to-5 bg-gradient-to-t py-2 w-full text-center rounded text-white/90 font-bold">
          <UserPlusIcon className="w-6 h-6 mr-2" />
          {ConfigProvider.getTranslation("Register")}
        </div>
        {!showVerify ? (
          <div className="mt-3 md:mt-10">
            <div className="block">
              <nav
                className="isolate flex divide-x divide-gray-700 rounded-lg shadow"
                aria-label="Tabs"
              >
                {tabs.map((tab, tabIdx) => (
                  <Link
                    key={tab.name}
                    to={tab.href}
                    className={classNames(
                      tab.current ? "bg-home-from-2" : "bg-home-to-2",
                      tabIdx === 0 ? "rounded-l-lg" : "",
                      tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                      "group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center focus:z-10 border-home-to-2"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        tab.current
                          ? "border-home-to-2 brightness-50 border-b-2"
                          : "",
                        "absolute inset-x-0 bottom-0 h-0.5"
                      )}
                    />
                  </Link>
                ))}
              </nav>
            </div>
            <form className="flex-none w-full" onSubmit={updateForm}>
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
              <div className="w-full md:grid md:grid-cols-2 md:gap-10 p-4 font-bold">
                <div className="w-full">
                  <div className="w-full text-center text-lg py-2 border-b mb-5 text-white">
                    {ConfigProvider.getTranslation(
                      "Register.PersonalInformation"
                    )}
                  </div>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="gender"
                      className="mb-1 text-xs sm:text-sm tracking-wid"
                    >
                      {ConfigProvider.getTranslation("Gender")}:
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <GenderRadio
                        onUpdate={(value) => {
                          setFormData({ ...formData, gender: value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="name"
                      className="mb-1 text-xs sm:text-sm tracking-wid"
                    >
                      {ConfigProvider.getTranslation("FirstName")}:
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                        placeholder={ConfigProvider.getTranslation("FirstName")}
                        value={formData.name}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="sname"
                      className="mb-1 text-xs sm:text-sm tracking-wid"
                    >
                      {ConfigProvider.getTranslation("Surname")}:
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <input
                        id="sname"
                        name="sname"
                        type="text"
                        className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                        placeholder={ConfigProvider.getTranslation("Surname")}
                        value={formData.sname}
                        onChange={handleChange}
                        required
                        maxLength={50}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="dateOfBirth"
                      className="mb-1 text-xs sm:text-sm tracking-wid"
                    >
                      {ConfigProvider.getTranslation("DateOfBirth")}:
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <input
                        id="dateOfBirth"
                        name="dateOfBirth"
                        type="date"
                        className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                        placeholder={ConfigProvider.getTranslation(
                          "DateOfBirth"
                        )}
                        value={formData.dateOfBirth}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="identity"
                      className="mb-1 text-xs sm:text-sm tracking-wid"
                    >
                      {ConfigProvider.getTranslation("IdentityNumber")}:
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <input
                        id="identity"
                        name="identity"
                        type="number"
                        className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                        placeholder={ConfigProvider.getTranslation(
                          "IdentityNumber"
                        )}
                        value={formData.identity}
                        onChange={handleChange}
                        required
                        maxLength={11}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="phone"
                      className="mb-1 text-xs sm:text-sm tracking-wid"
                    >
                      {ConfigProvider.getTranslation("Phone")}:
                    </label>
                    <div className="relative">
                      <input
                        id="phone"
                        name="phone"
                        type="text"
                        className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                        placeholder={ConfigProvider.getTranslation("Phone")}
                        value={formData.phone}
                        onChange={handleChange}
                        maxLength={20}
                      />
                    </div>
                  </div>
                  {btag.length > 0 && (
                    <div className="flex flex-col mb-6">
                      <label
                        htmlFor="postalCode"
                        className="mb-1 text-xs sm:text-sm tracking-wid"
                      >
                        {ConfigProvider.getTranslation("Btag")}:
                      </label>
                      <div className="relative">
                        <input
                          id="btag"
                          name="btag"
                          type="text"
                          className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                          value={btag}
                          readOnly
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="w-full">
                  <div className="w-full text-center text-lg py-2 border-b  mb-5">
                    {ConfigProvider.getTranslation(
                      "Register.AccountInformation"
                    )}
                  </div>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="uname"
                      className="mb-1 text-xs sm:text-sm tracking-wid"
                    >
                      {ConfigProvider.getTranslation("Username")}:
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10">
                        <UserIcon className="w-6 h-6 text-page-text" />
                      </div>

                      <input
                        id="uname"
                        name="uname"
                        type="text"
                        className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                        placeholder={ConfigProvider.getTranslation("Username")}
                        value={formData.uname}
                        onChange={handleChange}
                        required
                        maxLength={15}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="password"
                      className="mb-1 text-xs sm:text-sm tracking-wide"
                    >
                      {ConfigProvider.getTranslation("Password")}:
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10">
                        <KeyIcon className="w-6 h-6 text-page-text" />
                      </div>

                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                        placeholder="*******"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        maxLength={15}
                      />
                      <div
                        className="inline-flex items-center justify-center absolute right-0 top-0 h-full w-10 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <EyeIcon className="w-4 h-4 text-page-text" />
                        ) : (
                          <EyeSlashIcon className="w-4 h-4 text-page-text" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="password2"
                      className="mb-1 text-xs sm:text-sm tracking-wide"
                    >
                      {ConfigProvider.getTranslation("Password.Again")}:
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10">
                        <KeyIcon className="w-6 h-6 text-page-text" />
                      </div>

                      <input
                        id="password2"
                        name="password2"
                        type={showPassword ? "text" : "password"}
                        className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                        placeholder="*******"
                        value={formData.password2}
                        onChange={handleChange}
                        required
                        maxLength={15}
                      />
                      <div
                        className="inline-flex items-center justify-center absolute right-0 top-0 h-full w-10 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <EyeIcon className="w-4 h-4 text-page-text" />
                        ) : (
                          <EyeSlashIcon className="w-4 h-4 text-page-text" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="email"
                      className="mb-1 text-xs sm:text-sm tracking-wide"
                    >
                      {ConfigProvider.getTranslation("E-mail")}:
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10">
                        <AtSymbolIcon className="w-6 h-6 text-page-text" />
                      </div>

                      <input
                        id="email"
                        name="email"
                        type="email"
                        className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md pl-10 pr-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                        placeholder={ConfigProvider.getTranslation("E-mail")}
                        value={formData.email}
                        onChange={handleChange}
                        required
                        maxLength={255}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <div className="relative">
                      <CountryDropdown
                        name={
                          <>
                            {ConfigProvider.getTranslation("Country")}:
                            <span className="text-red-500">*</span>
                          </>
                        }
                        onUpdate={(value) => {
                          setFormData({ ...formData, country: value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <div className="relative">
                      <CurrencyDropdown
                        onUpdate={(value) => {
                          setFormData({ ...formData, currency: value });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="w-full text-center text-lg py-2 border-b my-5">
                    {ConfigProvider.getTranslation("ContactDetails")}
                  </div>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="address"
                      className="mb-1 text-xs sm:text-sm tracking-wid"
                    >
                      {ConfigProvider.getTranslation("Address")}:
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <textarea
                        id="address"
                        name="address"
                        className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                        placeholder={ConfigProvider.getTranslation("Address")}
                        value={formData.address}
                        onChange={handleChange}
                        required
                        maxLength={255}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="postalCode"
                      className="mb-1 text-xs sm:text-sm tracking-wid"
                    >
                      {ConfigProvider.getTranslation("PostalCode")}:
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <input
                        id="postalCode"
                        name="postalCode"
                        type="text"
                        className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                        placeholder={ConfigProvider.getTranslation(
                          "PostalCode"
                        )}
                        value={formData.postalCode}
                        onChange={handleChange}
                        required
                        maxLength={10}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <label
                      htmlFor="city"
                      className="mb-1 text-xs sm:text-sm tracking-wid"
                    >
                      {ConfigProvider.getTranslation("City")}:
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="relative">
                      <input
                        id="city"
                        name="city"
                        type="text"
                        className="w-full placeholder:text-page-text py-3 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                        placeholder={ConfigProvider.getTranslation("City")}
                        value={formData.city}
                        onChange={handleChange}
                        required
                        maxLength={20}
                      />
                    </div>
                  </div>
                  {/*<div className="flex flex-col mb-6">
                    <div className="relative">
                      <CountryDropdown
                        name={
                          <>
                            {ConfigProvider.getTranslation("Nationality")}:
                            <span className="text-red-500">*</span>
                          </>
                        }
                        onUpdate={(value) => {
                          setFormData({ ...formData, nationality: value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <div className="relative">
                      <CountryDropdown
                        name={
                          <>
                            {ConfigProvider.getTranslation("CountryOfBirth")}:
                            <span className="text-red-500">*</span>
                          </>
                        }
                        onUpdate={(value) => {
                          setFormData({ ...formData, countryOfBirth: value });
                        }}
                      />
                    </div>
                  </div>
                  */}
                </div>
                <div className="col-span-2">
                  <div className="flex flex-col mb-6">
                    <div className="relative flex px-1">
                      <div className="flex items-center">
                        <input
                          id="ConfirmTermsAndConditions"
                          name="ConfirmTermsAndConditions"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          required
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="ConfirmTermsAndConditions"
                          className="font-medium"
                        >
                          <span className="text-red-500">*</span>
                          {` ${ConfigProvider.getTranslation("Confirm")} `}
                          <Link
                            to="/cms/terms?print=1"
                            target="_blank"
                            className="text-indigo-600 hover:text-indigo-500 hover:underline"
                          >
                            {ConfigProvider.getTranslation(
                              "TermsAndConditions"
                            )}
                          </Link>
                          {` & `}
                          <Link
                            to="/cms/rga?print=1"
                            target="_blank"
                            className="text-indigo-600 hover:text-indigo-500 hover:underline"
                          >
                            {ConfigProvider.getTranslation("RGAAndGT&C")}
                          </Link>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <div className="relative flex px-1">
                      <div className="flex items-center">
                        <input
                          id="ConfirmPersonalInformation"
                          name="ConfirmPersonalInformation"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          required
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="ConfirmPersonalInformation"
                          className="font-medium"
                        >
                          <span className="text-red-500">*</span>
                          {` ${ConfigProvider.getTranslation(
                            "ConfirmPersonalInformation"
                          )}`}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <div className="relative flex px-1">
                      <div className="flex items-center">
                        <input
                          id="ConfirmAgeTermsConditions"
                          name="ConfirmAgeTermsConditions"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          required
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label
                          htmlFor="ConfirmAgeTermsConditions"
                          className="font-medium"
                        >
                          <span className="text-red-500">*</span>
                          {` ${ConfigProvider.getTranslation(
                            "ConfirmAgeTermsConditions"
                          )}`}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <button
                      type="submit"
                      className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-1 text-page-bg font-bold"
                      disabled={loading}
                    >
                      <span className="mr-2 uppercase">
                        {ConfigProvider.getTranslation("Register")}
                      </span>
                      {loading ? (
                        <span>
                          <LoadingSpinner className="w-6 h-6" />
                        </span>
                      ) : (
                        <span>
                          <ArrowRightCircleIcon className="w-6 h-6" />
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center py-3 md:py-10 px-3 z-10">
            <div className="flex flex-col w-full max-w-lg ">
              <div className="self-center text-xl sm:text-2xl text-color-1">
                {ConfigProvider.getTranslation("Successfully Registered")}
              </div>

              <div className="mt-3 md:mt-10">
                <div className="mt-2 mb-4">
                  {ConfigProvider.getTranslation(
                    "YourEmailAddressMustBeVerifiedToLogin"
                  )}
                </div>
                <div className="flex w-full">
                  <button
                    type="button"
                    className="mt-6 flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-2 w-full transition duration-150 ease-in bg-home-from-8 text-page-bg font-bold"
                    disabled={loading}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Home")}
                    </span>
                    {loading ? (
                      <span>
                        <LoadingSpinner className="w-6 h-6" />
                      </span>
                    ) : (
                      <span>
                        <ArrowRightCircleIcon className="w-6 h-6" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default RegisterFull;
