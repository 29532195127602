import React, { useEffect, useState } from "react";
import { ReactComponent as BankTransferLogo } from "../../../assets/svg/spPayments/bankTransfer.svg";
import { ReactComponent as CreditCardLogo } from "../../../assets/svg/spPayments/creditCard.svg";
import { ReactComponent as PayfixLogo } from "../../../assets/svg/spPayments/payfix.svg";
import { ReactComponent as BitcoinLogo } from "../../../assets/svg/spPayments/bitcoin.svg";
import { ReactComponent as PaparaLogo } from "../../../assets/svg/spPayments/papara.svg";
import { ReactComponent as TetherLogo } from "../../../assets/svg/spPayments/tether.svg";
import { ReactComponent as TronLogo } from "../../../assets/svg/spPayments/tron.svg";
import { useConfig } from "../../../context/ConfigContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ArrowUpIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import {
  LanguageDropdown,
  TimezoneDropdown,
} from "../Header/common/HeaderHelpers";

function Footer() {
  const ConfigProvider = useConfig();
  const conf = ConfigProvider.config;
  return (
    <footer className="bg-footer-bg p-5 text-white">
      <div className="container mx-auto mb-5 grid grid-cols-2 lg:grid-cols-4 text-center lg:text-left">
        <Menus />
        {/* Column 5 */}
        <div className="col-span-2 mb-4 space-y-4 md:col-span-1 mt-10 lg:mt-0">
          <LanguageDropdown showTitle={true} />
          <TimezoneDropdown showTitle={true} />
        </div>
      </div>
      <div className="hidden container mx-auto grid-cols-2 gap-4 border-y border-page-text/15 px-8 py-8 md:grid md:grid-cols-7 lg:px-20">
        {/* <BitcoinLogo className="fill-page-text w-full" /> */}
        <PaparaLogo className="fill-page-text w-full" />
        <BankTransferLogo className="fill-page-text w-full" />
        <CreditCardLogo className="fill-page-text w-full" />
        <PayfixLogo className="fill-page-text w-full" />
        <BitcoinLogo className="fill-page-text w-full" />
        <TetherLogo className="fill-page-text w-full" />
        <TronLogo className="fill-page-text w-full" />
      </div>

      <div className="container mx-auto text-center text-[13px] text-page-text grid-cols-2 lg:grid-cols-1">
        <div className=" flex h-[70px] items-center justify-center">
          <LazyLoadImage
            alt={conf.hostnames.name + " Logo"}
            src={process.env.REACT_APP_CDN_ENDPOINT + conf.hostnames.logo}
            className="h-6 sm:h-9"
          />
        </div>

        <div
          className="lg:flex items-center justify-center my-5 space-x-4"
          dangerouslySetInnerHTML={{
            __html: ConfigProvider.config.cms.footerCustom,
          }}
        ></div>

        <p>
          &copy;
          {` ${`${conf.hostnames.name} | ${ConfigProvider.getTranslation(
            "AllRightsReserved"
          )}`}`}
        </p>
      </div>
      <ScrollToTopButton />
    </footer>
  );
}
function Menus() {
  const ConfigProvider = useConfig();
  const user = ConfigProvider.config.user;
  const menus = ConfigProvider.config.menus.hasOwnProperty("footer")
    ? ConfigProvider.config.menus["footer"]
    : [];
  const renderSubItems = (obj) => {
    return obj
      .filter(
        (x) =>
          (x.type === 0) |
          (x.type === 1 && user != null) |
          (x.type === 2 && user === null)
      )
      .map((item, index) => (
        <li className="mb-4" key={index}>
          {item.target === "_self" ? (
            <NavLink to={item.link}>
              {ConfigProvider.translateItem(item)}
            </NavLink>
          ) : (
            <a href={item.link} target={item.target}>
              {ConfigProvider.translateItem(item)}
            </a>
          )}
        </li>
      ));
  };
  const getSubItems = (menu) => {
    const subMenus = menus.filter((x) => x.parent === menu._id);
    return subMenus.length ? (
      <ul className="text-sm font-normal text-page-text">
        {renderSubItems(subMenus)}
      </ul>
    ) : (
      ""
    );
  };
  return menus
    .filter(
      (x) =>
        x.parent === "000000000000000000000000" &&
        (x.type === 0) |
          (x.type === 1 && user != null) |
          (x.type === 2 && user === null)
    )
    .map((group, index) => (
      <div key={index}>
        <h2 className="mb-4 text-[15px] font-semibold">
          {ConfigProvider.translateItem(group)}
        </h2>
        {getSubItems(group)}
      </div>
    ));
}

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > window.outerHeight - window.innerHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  return (
    <>
      {isVisible && (
        <button
          className="bg-page-text text-page-bg fixed bottom-16 right-4 rounded-full p-3 shadow-lg transition lg:bottom-4"
          onClick={scrollToTop}
        >
          <ArrowUpIcon className="h-5 w-5" />
        </button>
      )}
    </>
  );
}

export default Footer;
