import React, { useEffect, useState, useRef } from "react";

function PageEaseInEffect({ children, className = "", duration = 300 }) {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      // eslint-disable-next-line
      if (observer && elementRef.current) observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={elementRef}
      className={`${className} ${
        duration === 300
          ? "duration-300"
          : duration === 500
          ? "duration-700"
          : duration === 700
          ? "duration-500"
          : "duration-1000"
      } transition-all ease-in-out ${
        isVisible ? "translate-y-0 opacity-100" : "translate-y-8 opacity-50"
      }`}
    >
      {children}
    </div>
  );
}

export default PageEaseInEffect;
