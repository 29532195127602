import React, { useState, useEffect } from "react";
import { useConfig } from "../../../context/ConfigContext";
import Requests from "../../../helpers/Requests";
import HorizontalScrollGames from "./HorizontalScrollGames";

function RecommendGames({ game = null, offset = 16 }) {
  const ConfigProvider = useConfig();
  const [data, setData] = useState([]);
  const callCasinoGames = () => {
    const fetchData = async () => {
      let postData = {
        offset,
      };
      if (game) {
        postData.game = game;
      }
      const data = await Requests.postData(
        "/casino/getRecommedGames",
        postData
      );
      return data;
    };

    fetchData()
      .then((res) => {
        if (res.data.type === "success") {
          setData(res.data.data.data);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    callCasinoGames();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="mb-4 -mt-2 px-2">
      <HorizontalScrollGames
        title={ConfigProvider.getTranslation("RecommendGames")}
        games={data}
        game={game}
      />
    </div>
  );
}

export default RecommendGames;
