import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import Requests from "../helpers/Requests";
import { useRef } from "react";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import CasinoListProvider from "../components/Common/Casino/CasinoListProvider";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

function CasinoProviders() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const [callTimeOut, setCallTimeOut] = useState(null);
  const callGames = () => {
    setLoading(true);
    if (callTimeOut) {
      window.clearTimeout(callTimeOut);
    }
    const timeout = window.setTimeout(() => {
      callCasinoGames();
    }, 100);
    setCallTimeOut(timeout);
    return () => {
      window.clearTimeout(callTimeOut);
    };
  };
  useEffect(() => {
    if (page !== 1) {
      callGames();
    } else {
      setPage(1);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    callGames();
    // eslint-disable-next-line
  }, [page]);

  const callCasinoGames = () => {
    let selPage = page;
    const fetchData = async () => {
      let postData = {
        offset: 50,
        page: page,
      };
      const data = await Requests.postData("/casino/getProviders", postData);
      return data;
    };

    fetchData()
      .then((res) => {
        if (res.data.type === "success") {
          if (selPage === 1) {
            setData(res.data.data.data);
          } else {
            let oldData = [...data];
            oldData.push(...res.data.data.data);
            setData(oldData);
          }

          if (res.data.data.page === 1) {
            let totalItem = res.data.data.total;
            let totalPage =
              parseInt(totalItem / 50) + (totalItem % 50 === 0 ? 0 : 1);
            setTotalPage(totalPage);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        Requests.diagnostics(err, navigate);
        setLoading(false);
      });
  };

  const elementRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  const checkVisibility = () => {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        if (!isVisible) {
          setIsVisible(true);
          setPage(page + 1);
        }
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);

    return () => {
      window.removeEventListener("scroll", checkVisibility);
    };
    // eslint-disable-next-line
  }, [isVisible, loading]);

  return (
    <>
      <div className="max-w-7xl p-4 sm:px-6 2xl:px-4 overflow-hidden mx-auto">
        <div className="w-full flex justify-between my-6 items-center">
          <div className="flex items-center rounded-lg h-10">
            <div className="font-bold flex items-center text-lg">
              {ConfigProvider.getTranslation("Providers")}
            </div>
          </div>
        </div>
        <div className="w-full py-2 text-sm">
          {data.length ? (
            <>
              <CasinoListProvider loading={loading} data={data} />
              {totalPage > page ? (
                <>
                  <div>
                    <div
                      className="flex w-full justify-center my-6"
                      ref={elementRef}
                    >
                      <LoadingSpinner className="w-6" />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : !loading ? (
            <>
              <div className="flex flex-col items-center justify-center">
                <div className="grid grid-flow-row mt-4">
                  <div className="py-10 text-center center flex-col">
                    <ExclamationTriangleIcon className="w-48 h-48 fill-current" />
                    <div className="leading-5 mt-4">
                      {ConfigProvider.getTranslation("Opps")}!
                    </div>
                    <div className="leading-5 mt-4">
                      {ConfigProvider.getTranslation("ThereIsNoDataYet")}!
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default CasinoProviders;
