import { React, useEffect, useState } from "react";
import Slider from "../components/Common/Slider";
import { useDeviceSize } from "../context/DeviceSizeContext";
import Requests from "../helpers/Requests";
import Popup from "../components/Common/Popup";
import Jackpot from "../components/Common/Jackpot";
import Brands from "../components/Common/Brands";
import CustomHtmlComponent from "../components/Common/CustomHtmlComponent";
import CardsComponent from "../components/Common/CardsComponent";
import TopWinners from "../components/Common/TopWinners";
import LastWinnersModule from "../components/Common/Casino/LastWinnersModule";
import TopCasinoGames from "../components/Common/Casino/TopCasinoGames";

function Home() {
  return (
    <>
      <HomeLayout />
      <HomePopup />
    </>
  );
}
function HomeLayout() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [topGames, setTopGames] = useState({});
  const getPageItems = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/general/getPageItems", { location: "home" })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
          } else {
            setData(res.data);
            setTopGames(res.topGames);
            if (res.topGames && Object.keys(res.topGames).length > 0) {
              setTopCasinoGamesExport(Object.keys(res.topGames)[0]);
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          Requests.diagnostics(err);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getPageItems();
    // eslint-disable-next-line
  }, []);
  const DeviceSizeProvider = useDeviceSize();

  const platform = DeviceSizeProvider.deviceType === "sm" ? "mobile" : "web";
  const [topCasinoGamesExport, setTopCasinoGamesExport] = useState(
    Object.keys(topGames).length > 0 ? Object.keys(topGames)[0] : ""
  );

  return (
    <>
      <div className="max-w-7xl px-2 overflow-hidden mx-auto py-4">
        {data.map((group, gindex) => (
          <div
            key={gindex}
            className={`w-full my-2 ${
              group.items.length > 1 ? "md:flex md:space-x-2" : ""
            }`}
          >
            {group.items.map((item, index) => (
              <div key={index} className={item.className}>
                {item.type === "slider" ? (
                  <Slider location={item.config.location} platform={platform} />
                ) : item.type === "jackpot" ? (
                  <Jackpot type={item.config.type} />
                ) : item.type === "winnersModule" ? (
                  <TopWinners
                    type={item.config.type}
                    transaction={item.config.transaction}
                    period={item.config.period}
                  />
                ) : item.type === "topCasinoGames" ? (
                  <>
                    {topCasinoGamesExport === item.config.category &&
                    topGames.hasOwnProperty(item.config.category) ? (
                      <TopCasinoGames data={topGames} />
                    ) : null}
                  </>
                ) : item.type === "brands" ? (
                  <Brands />
                ) : item.type === "content" ? (
                  <></>
                ) : item.type === "html" ? (
                  <CustomHtmlComponent id={item.config.id} />
                ) : item.type === "lastWinnersModule" ? (
                  <LastWinnersModule />
                ) : item.type === "cardsComponent" ? (
                  <CardsComponent />
                ) : item.type === "marquee" ? (
                  <></>
                ) : null}
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}

function HomePopup() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const getPopups = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/general/getPopups", {})
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
          } else {
            setData(res.data);
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    const isPopupShownBefore = sessionStorage.getItem("isPopupShown");
    if (!isPopupShownBefore) {
      getPopups();
      sessionStorage.setItem("isPopupShown", true);
    }
    // eslint-disable-next-line
  }, []);
  return data.map((item, i) => (
    <Popup
      key={i}
      title={item.title}
      content={
        <img
          srcSet={`//demolinker.com/upload/img/${item.image}`}
          className="max-w-[calc(100vw-100px)] max-h-[calc(100vh-250px)] w-full h-full"
          alt={item.title}
        />
      }
    />
  ));
}

export default Home;
