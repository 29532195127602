import { useState, useEffect, useRef } from "react";
import { useConfig } from "../../context/ConfigContext";
import Requests from "../../helpers/Requests";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "../../assets/css/topWinners.css";
import { Autoplay } from "swiper/modules";
import { TrophyIcon } from "@heroicons/react/24/outline";
import { LazyLoadImage } from "react-lazy-load-image-component";

function TopWinners({ type, transaction, period }) {
  const swiperRef = useRef(null);
  const ConfigProvider = useConfig();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const cdnEndpoint = process.env.REACT_APP_CDN_ENDPOINT;
  const [periods, setPeriods] = useState(period);
  useEffect(() => {
    if (ConfigProvider.config.onlySports && transaction === "casino") {
      setLoading(false);
    } else {
      getItems();
    }
    // eslint-disable-next-line
  }, [type, transaction, periods]);

  const getItems = () => {
    const fetchData = async () => {
      const data = await Requests.postData("/casino/getWinners", {
        period: periods,
        type: type,
        transaction: transaction,
      });
      return data;
    };

    fetchData()
      .then((res) => {
        if (res.data.type === "success") {
          setData(res.data.data);
          if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(0, 0);
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const changeTab = (val) => {
    if (periods !== val && !loading) {
      setLoading(true);
      setPeriods(val);
    }
  };
  return !loading && data.length === 0 ? (
    <></>
  ) : (
    <>
      <div className="mt-4 flex h-8 items-center mb-1.5 sm:mt-6">
        <div className="flex items-center w-full font-bold space-x-2">
          <div>
            <TrophyIcon className="w-8 h-8 text-menu-bg" />
          </div>
          <div className="text-xl md:text-2xl">
            {ConfigProvider.getTranslation("TopWinners")}
          </div>
          <div className="grow flex justify-end">
            <div className="ml-2 pl-2 hidden gap-2 lg:!flex">
              <TabButton
                text={ConfigProvider.getTranslation("All")}
                periods={periods}
                name="allTimes"
                click={(val) => {
                  changeTab(val);
                }}
              />
              <TabButton
                text={ConfigProvider.getTranslation("Daily")}
                periods={periods}
                name="daily"
                click={(val) => {
                  changeTab(val);
                }}
              />
              <TabButton
                text={ConfigProvider.getTranslation("Weekly")}
                periods={periods}
                name="weekly"
                click={(val) => {
                  changeTab(val);
                }}
              />
              <TabButton
                text={ConfigProvider.getTranslation("Monthly")}
                periods={periods}
                name="monthly"
                click={(val) => {
                  changeTab(val);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-8">
        <Swiper
          ref={swiperRef}
          spaceBetween={15}
          slidesPerView={"auto"}
          loop={true}
          speed={3000}
          modules={[Autoplay]}
          autoplay={{
            delay: 0,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
        >
          {loading
            ? Array(40)
                .fill()
                .map((item, i) => (
                  <SwiperSlide key={i} className="inline-block">
                    <div className="w-72 min-h-22 p-2 bg-layout-bg rounded-lg flex gap-2">
                      <div className="w-32 flex items-center">
                        <div className="bg-layout-border animate-pulse w-full aspect-[16/11] rounded">
                          &nbsp;
                        </div>
                      </div>
                      <div className="grow flex items-center min-w-0">
                        <div className="flex flex-col font-bold text-sm w-full">
                          <div className="bg-layout-border animate-pulse w-full rounded mb-1">
                            &nbsp;
                          </div>
                          <div className="bg-layout-border animate-pulse w-full rounded mb-1">
                            &nbsp;
                          </div>
                          <div className="bg-layout-border animate-pulse w-full rounded">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </SwiperSlide>
                ))
            : data
                .sort((a, b) => b.baseAmount - a.baseAmount)
                .map((item, index) => (
                  <SwiperSlide
                    key={index}
                    className="w-72 h-22 p-2 bg-layout-bg rounded-lg"
                  >
                    <Link
                      to={"/casinoGame/" + item.extra.game}
                      className={`flex gap-2 hover:opacity-80 cursor-pointer}`}
                    >
                      <div className="w-32 flex items-center">
                        <LazyLoadImage
                          src={
                            item.extra.imageUrl.startsWith("casino/")
                              ? `${cdnEndpoint}/upload/img/${item.extra.imageUrl}`
                              : item.extra.imageUrl
                          }
                          className="w-32 h-24 rounded"
                        />
                      </div>
                      <div className="grow flex items-center min-w-0">
                        <div className="flex flex-col font-bold text-sm min-w-0">
                          <div className="truncate">{item.uname}</div>
                          <div className="text-menu-bg truncate">
                            {`${item.amount} ${
                              item.currency === "TRY"
                                ? "₺"
                                : item.currency === "EUR"
                                ? "€"
                                : item.currency
                            }`}
                          </div>
                          <div className="truncate">
                            {item.extra.displayName}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
        </Swiper>
      </div>
    </>
  );
}
function TabButton({ text, periods, name, click }) {
  const active = periods === name;
  return (
    <button
      className={`btn flex-1 p-1 center h-auto whitespace-nowrap rounded-none border-b-2 text-sm font-semibold ${
        active ? "border-b-menu-bg" : "border-b-transparent"
      }`}
      type="button"
      onClick={() => {
        click(name);
      }}
    >
      <span className={`${active ? "text-layout-text" : ""}`}>{text}</span>
    </button>
  );
}

export default TopWinners;
