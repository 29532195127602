import React, { useEffect, useRef, useState } from "react";
import {
  Bars3Icon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

import Requests from "../helpers/Requests";
import { Link, useNavigate } from "react-router-dom";
import { useConfig } from "../context/ConfigContext";
import Slider from "../components/Common/Slider";

import { classNames } from "../helpers/Helpers";
import { useDeviceSize } from "../context/DeviceSizeContext";
import Jackpot from "../components/Common/Jackpot";
import { SvgLoader } from "react-svgmt";
import CasinoGame from "../components/Common/Casino/CasinoGame";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import Dropdown from "../components/Common/FormElements/Dropdown";

function Casino({
  favorites = false,
  path = undefined,
  cid = undefined,
  title = undefined,
}) {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("0");
  const [category, setCategory] = useState(cid ? cid : "");
  useEffect(() => {
    cid !== category && setCategory(cid);
    // eslint-disable-next-line
  }, [cid]);
  const [provider, setProvider] = useState([""]);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [providers, setProviders] = useState({});
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const allText = ConfigProvider.getTranslation("Filter.All");

  const [showModal, setShowModal] = useState(false);

  const [oldPath, setOldPath] = useState(path);

  useEffect(() => {
    if (path !== oldPath) {
      setSearch("");
      setCategory("");
      setProvider([]);
      if (page !== 1) {
        setPage(1);
      } else {
        callGames();
      }
      window.scrollTo(0, 0);
      setOldPath(path);
    } else if (category !== oldCategory) {
      setSearch("");
      setProvider([]);
      if (page !== 1) {
        setPage(1);
      } else {
        callGames();
      }
      window.scrollTo(0, 0);
      setOldCategory(category);
    } else if (provider !== oldProvider) {
      setSearch("");
      if (page !== 1) {
        setPage(1);
      } else {
        callGames();
      }
      window.scrollTo(0, 0);
      setOldProvider(provider);
    } else {
      callGames();
    }
    // eslint-disable-next-line
  }, [path, category, provider, search, page, sortBy]);

  const [oldCategory, setOldCategory] = useState(category);
  const [oldProvider, setOldProvider] = useState(provider);

  const [callTimeOut, setCallTimeOut] = useState(null);
  const callGames = () => {
    if (ConfigProvider.config.user === null && favorites) {
      navigate("/");
    } else {
      setLoading(true);
      if (callTimeOut) {
        window.clearTimeout(callTimeOut);
      }
      const timeout = window.setTimeout(() => {
        callCasinoGames();
      }, 100);
      setCallTimeOut(timeout);
      return () => {
        window.clearTimeout(callTimeOut);
      };
    }
  };
  const callCasinoGames = () => {
    let selPage = page;
    setLoading(true);
    const fetchData = async () => {
      const data = await Requests.postData("/casino/getGames", {
        path: path,
        search: search,
        category: category,
        page: selPage,
        providers: provider,
        sortBy: sortBy,
        favorites: favorites,
      });
      return data;
    };

    fetchData()
      .then((res) => {
        if (res.data.type === "success") {
          if (selPage === 1) {
            setData(res.data.data.data);
          } else {
            let oldData = [...data];
            oldData.push(...res.data.data.data);
            setData(oldData);
          }
          if (res.data.data.page === 1) {
            let totalItem = res.data.data.total;
            let totalPage =
              parseInt(totalItem / 50) + (totalItem % 50 === 0 ? 0 : 1);
            setTotalPage(totalPage);
            res.data.data.providers[""] = {
              _id: "",
              name: allText,
              tag: "",
              icon: "",
              active: true,
              order: 0,
              lobby: false,
              count: Object.values(res.data.data.providers).reduce(function (
                accumulator,
                currentValue
              ) {
                return accumulator + currentValue.count;
              },
              0),
            };
            setProviders(res.data.data.providers);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        Requests.diagnostics(err, navigate);
        setLoading(false);
      });
  };
  const selectProvider = (key) => {
    if (key === "") {
      setProvider([""]);
    } else {
      let updatedItems = [...provider];
      updatedItems = updatedItems.filter((x) => x !== "");
      if (updatedItems.indexOf(key) === -1) {
        updatedItems.push(key);
      } else {
        updatedItems.splice(updatedItems.indexOf(key), 1);
      }
      setProvider(updatedItems);
    }
  };
  const providersText = ConfigProvider.getTranslation("Providers");

  const DeviceSizeProvider = useDeviceSize();
  const [sliderPlatform, setSliderPlatform] = useState(
    DeviceSizeProvider.deviceType === "sm" ? "mobile" : "web"
  );

  useEffect(() => {
    const newPlatform =
      DeviceSizeProvider.deviceType === "sm" ? "mobile" : "web";
    if (sliderPlatform !== newPlatform) {
      setSliderPlatform("");
      setTimeout(() => {
        setSliderPlatform(newPlatform);
      }, 500);
    }
    // eslint-disable-next-line
  }, [DeviceSizeProvider.deviceType]);

  const elementRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  const checkVisibility = () => {
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        if (!isVisible) {
          setIsVisible(true);
          setPage(page + 1);
        }
      } else {
        setIsVisible(false);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", checkVisibility);

    return () => {
      window.removeEventListener("scroll", checkVisibility);
    };
    // eslint-disable-next-line
  }, [isVisible, loading]);

  return (
    <div className="mx-auto w-full py-3 px-3 max-w-screen-2xl">
      {title ? (
        <div className="w-full flex justify-between my-6 items-center">
          <div className="flex items-center rounded-lg h-10">
            <div className="font-bold flex items-center text-lg">{title}</div>
          </div>
        </div>
      ) : (
        <></>
      )}{" "}
      {path && path.length ? (
        <>
          <div className="w-full md:flex md:space-x-2 lg:space-x-4">
            <div className="w-full items-center flex flex-col mb-2 md:mb-0">
              <Slider
                platform={
                  DeviceSizeProvider.deviceType === "sm" ? "mobile" : "web"
                }
                location={"casino/" + path}
              />
            </div>
          </div>
          {ConfigProvider.config.jackpot && (
            <div className="w-full md:flex md:space-x-2 lg:space-x-4 mt-4">
              <div className="w-full items-center flex flex-col mb-2 md:mb-0">
                <Jackpot type="casino" />
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
      <div className="py-5 text-color-3">
        <div className="flex flex-col md:flex-row bg-layout-bg rounded-md w-full text-sm md:space-x-5">
          {path && path.length ? (
            <div className="grow flex">
              <ul
                className={
                  (showDropdown ? "" : "truncate h-[55px] ") + "grow md:px-2"
                }
              >
                <CasinoCagetories
                  category={category}
                  setCategory={(val) => {
                    if (favorites) {
                      setCategory(val);
                    } else {
                      navigate(
                        `/casino${val ? `/category` : ``}/${path}${
                          val ? `/${val}` : ``
                        }`
                      );
                    }
                  }}
                  allText={allText}
                  path={path}
                />
              </ul>
              <div className="px-4 pt-5">
                {showDropdown ? (
                  <ChevronUpIcon
                    className="w-4 h-4 cursor-pointer text-page-text"
                    onClick={() => setShowDropdown(!showDropdown)}
                  />
                ) : (
                  <ChevronDownIcon
                    className="w-4 h-4 cursor-pointer text-page-text"
                    onClick={() => setShowDropdown(!showDropdown)}
                  />
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          {ConfigProvider.config.prefences.casinoSortByDropdown ? (
            <div className={"md:min-w-fit md:pt-1"}>
              <SortByDropdown
                sortBy={sortBy}
                setSortBy={setSortBy}
                loading={loading}
              />
            </div>
          ) : (
            <></>
          )}
          <div className={"md:min-w-fit md:pt-1"}>
            <Search search={search} setSearch={setSearch} />
          </div>
        </div>
        <hr className="border-0 w-full h-5 " />
        <div className="">
          <div
            onClick={() => {
              setShowModal(true);
            }}
            className="bg-layout-bg rounded-md flex justify-center items-center px-2 text-sm py-2 cursor-pointer uppercase"
          >
            <Bars3Icon className="w-4 h-4 mr-2" /> {providersText}
          </div>
        </div>
        <hr className="border-0 w-full h-5" />
        <div className="w-full md:flex">
          <div className="grow">
            <CasinoGame data={data} loading={loading} />
            {totalPage > page ? (
              <>
                <div>
                  <div
                    className="flex w-full justify-center my-6"
                    ref={elementRef}
                  >
                    <LoadingSpinner className="w-6" />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {DeviceSizeProvider.deviceType === "sm" ? (
        <ProviderModal
          provider={provider}
          selectProvider={selectProvider}
          providers={providers}
          showModal={showModal}
          setShowModal={setShowModal}
          closeText={ConfigProvider.getTranslation("Close")}
        />
      ) : (
        showModal && (
          <ProviderModalDesktop
            provider={provider}
            selectProvider={setProvider}
            providers={providers}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )
      )}
    </div>
  );
}

function Search({ search, setSearch }) {
  const ConfigProvider = useConfig();
  return (
    <div className="flex items-center p-2 justify-center w-full">
      <div className="bg-page-bg rounded-md flex w-full">
        <button className="w-8 ml-1 flex items-center justify-center">
          <MagnifyingGlassIcon className="w-5 h-5 text-page-text" />
        </button>
        <input
          type="text"
          placeholder={ConfigProvider.getTranslation("Search") + ".."}
          className="bg-page-bg text-page-text placeholder:text-page-text rounded-md border-none outline-none w-full text-sm px-2 py-2"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  );
}
function SortByDropdown({ sortBy, setSortBy, loading }) {
  const ConfigProvider = useConfig();

  const options = [
    { id: "0", name: ConfigProvider.getTranslation("Popular") },
    { id: "1", name: ConfigProvider.getTranslation("A-Z") },
    { id: "2", name: ConfigProvider.getTranslation("Z-A") },
    { id: "3", name: ConfigProvider.getTranslation("New") },
  ];
  return (
    <div className="px-2 mb-2 md:px-0 md:mb-0 md:w-32">
      <Dropdown
        name=""
        selected={sortBy}
        setSelected={(val) => {
          !loading && setSortBy(val);
        }}
        items={options}
      />
    </div>
  );
}

function CasinoCagetories({ path, allText, category, setCategory }) {
  const ConfigProvider = useConfig();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    callCasinoCategories();
    // eslint-disable-next-line
  }, [path]);
  const callCasinoCategories = () => {
    const fetchData = async () => {
      const data = await Requests.postData("/casino/getCasinoCategories", {
        path: path,
      });
      return data;
    };

    fetchData()
      .then((res) => {
        if (res.data.type === "success") {
          let allData = res.data.data;
          if (allData.length > 0) {
            allData.unshift({
              id: "",
              name: allText,
              translation: {
                en: allText,
              },
            });
          }

          setData(allData);
        }
        setLoading(false);
      })
      .catch((err) => {
        Requests.diagnostics(err, navigate);
        setLoading(false);
      });
  };
  return loading | (data.length === 0) ? (
    <>
      {Array(10)
        .fill()
        .map((item, index) => (
          <li key={index} className="py-5 px-1 float-left">
            <div
              className={
                (loading ? "animate-pulse " : "") +
                "bg-layout-bg rounded w-[75px] h-[25px]"
              }
            >
              &nbsp;
            </div>
          </li>
        ))}
    </>
  ) : (
    <>
      {data.map((item, index) => (
        <li
          key={index}
          className="cursor-pointer py-2.5 px-1 float-left"
          onClick={() => {
            setCategory(item.id);
          }}
        >
          <div
            className={classNames(
              category === item.id ? "bg-layout-border text-white " : "",
              "text-page-text text-sm px-6 py-2 font-semibold rounded-md cursor-pointer hover:bg-layout-border hover:text-white flex space-x-1"
            )}
          >
            {item.icon && (
              <SvgLoader
                svgXML={item.icon}
                className="w-5 h-5 mr-0.5 text-current fill-current"
              ></SvgLoader>
            )}
            <span>{ConfigProvider.translateItem(item)}</span>
          </div>
        </li>
      ))}
    </>
  );
}

function ProviderModal({
  showModal,
  providers,
  selectProvider,
  setShowModal,
  closeText,
  provider,
}) {
  useEffect(() => {
    if (showModal) {
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.paddingRight = "0px";
    } else {
      document.documentElement.style.overflow = "auto";
      document.documentElement.style.paddingRight = "0px";
    }
    return () => {
      document.documentElement.style.overflow = "auto";
      document.documentElement.style.paddingRight = "0px";
    };
  }, [showModal]);

  return showModal ? (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-layout-bg text-left shadow-xl transition-all w-full sm:my-8 sm:max-w-lg">
            <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="grid grid-cols-3 gap-2 text-xs">
                {Object.keys(providers)
                  .sort((a, b) => providers[a].order - providers[b].order)
                  .map((key, index) => (
                    <div
                      key={key}
                      className={
                        (provider.indexOf(key) > -1
                          ? "bg-gradient-gold-a border-2 border-page-bg button-color-3 text-layout-bg "
                          : "bg-layout-border  ") +
                        "p-2 rounded flex flex-col items-center justify-center font-bold"
                      }
                      onClick={() => {
                        selectProvider(key);
                      }}
                    >
                      <div className="text-center mb-2 ">
                        {providers[key].name}
                      </div>
                      <div className="text-xs text-page-text">
                        ({providers[key].count})
                      </div>
                    </div>
                  ))}
              </div>

              <div className="px-4 py-3">
                <button
                  type="button"
                  className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-home-to-4  px-3 py-2 text-sm font-semibold text-color-4 shadow-sm"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <XCircleIcon className="w-4 h-4 mr-2" />
                  {closeText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
function ProviderModalDesktop({
  showModal,
  providers,
  selectProvider,
  setShowModal,

  provider,
}) {
  const ConfigProvider = useConfig();
  const [search, setSearch] = useState("");
  const [provider2, setProvider2] = useState(provider);
  useEffect(() => {
    if (showModal) {
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.paddingRight = "0px";
    } else {
      document.documentElement.style.overflow = "auto";
      document.documentElement.style.paddingRight = "0px";
    }
    return () => {
      document.documentElement.style.overflow = "auto";
      document.documentElement.style.paddingRight = "0px";
    };
  }, [showModal]);
  const mapFunction = () => {
    let allKeys = Object.keys(providers);
    return allKeys
      .filter((x) =>
        search.length > 0
          ? providers[x].name.toLowerCase().includes(search.toLowerCase())
          : true
      )
      .sort((a, b) => providers[a].order - providers[b].order);
  };
  return showModal ? (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full justify-center p-4 text-center items-start sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-layout-bg text-left shadow-xl transition-all w-full sm:my-8 sm:max-w-3xl">
            <div className="px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <h2 className="border-b border-layout-border mt-2 mb-4 pb-2">
                {ConfigProvider.getTranslation("Providers")}
                <Link
                  className="float-right"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <XMarkIcon className="w-5 h-5 text-home-from-4" />
                </Link>
              </h2>
              <form className="">
                <Search search={search} setSearch={setSearch} />
              </form>
              <div className="p-2 overflow-auto max-h-[calc(100vh/1.5)]">
                <div className="grid grid-cols-3 gap-2 text-xs">
                  {mapFunction().map((key, index) => (
                    <div
                      key={key}
                      className={
                        "bg-layout-border px-2 py-3 rounded flex items-center justify-center font-bold cursor-pointer"
                      }
                      onClick={() => {
                        setProvider2((prev) => {
                          if (key === "") {
                            return [""];
                          } else {
                            let updatedItems = [...prev];
                            updatedItems = updatedItems.filter((x) => x !== "");
                            if (updatedItems.indexOf(key) === -1) {
                              updatedItems.push(key);
                            } else {
                              updatedItems.splice(updatedItems.indexOf(key), 1);
                            }
                            return updatedItems;
                          }
                        });
                      }}
                    >
                      <div className="mr-1.5 flex items-center">
                        <label
                          className="relative flex items-center rounded-full cursor-pointer"
                          htmlFor={"check-" + key}
                        >
                          <input
                            id={"check-" + key}
                            type="checkbox"
                            className="h-4 w-4 appearance-none rounded bg-page-bg checked:bg-gradient-gold-a"
                            checked={provider2.includes(key)}
                          />
                          {provider2.includes(key) && (
                            <span className="absolute text-page-bg transition-opacity pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3.5 w-3.5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                stroke="currentColor"
                                stroke-width="1"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          )}
                        </label>
                      </div>
                      <div className="text-left grow truncate min-w-0 mr-1">
                        {providers[key].name}
                      </div>
                      <div className="text-xs text-page-text">
                        ({providers[key].count})
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="px-4 py-3">
                <button
                  type="button"
                  className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-home-to-3  px-3 py-2 text-sm font-semibold text-color-4 shadow-sm"
                  onClick={() => {
                    selectProvider(provider2);
                    setShowModal(false);
                  }}
                >
                  <CheckIcon className="w-4 h-4 mr-2" />
                  {ConfigProvider.getTranslation("Drowndown.SelectOne")}
                  {provider2.filter((x) => x !== "").length > 0 && (
                    <span className="ml-1">({provider2.length})</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
export default Casino;
