import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/24/solid";
import Requests from "../../helpers/Requests";
import { useNavigate } from "react-router-dom";
import CasinoGame from "./Casino/CasinoGame";

function SearchModal({ callback }) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const ConfigProvider = useConfig();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [timeoutId, setTimeoutId] = useState(null);
  const callCasinoGames = () => {
    if (!loading) {
      setLoading(true);
      const fetchData = async () => {
        const data = await Requests.postData("/casino/getGames", {
          search: search,
        });
        return data;
      };

      fetchData()
        .then((response) => {
          let res = response.data;
          if (res.type === "success") {
            setData(res.data.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          Requests.diagnostics(err, navigate);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (!loading) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const newTimeoutId = setTimeout(
        () => {
          callCasinoGames();
        },
        search.length ? 250 : 0
      );
      setTimeoutId(newTimeoutId);
    }
    // eslint-disable-next-line
  }, [search]);
  return (
    <div className="flex flex-col mt-10">
      <Search search={search} setSearch={setSearch} />
      {search && search.length > 0 ? (
        <div className="p-2 my-2">
          <h3>{ConfigProvider.getTranslation("SearchResults")}</h3>
        </div>
      ) : (
        <div className="p-2 my-2">
          <h3>{ConfigProvider.getTranslation("PopularGames")}</h3>
        </div>
      )}

      {!loading && data.length === 0 ? (
        <div className="w-full p-2 text-lg flex flex-col items-center">
          <XCircleIcon className="w-16 h-16 text-color-3 mb-2" />
          {ConfigProvider.getTranslation("NoData")}
        </div>
      ) : (
        <div className="px-2 overflow-y-scroll max-h-[calc(100vh-350px)]">
          <CasinoGame data={data} loading={loading} />
        </div>
      )}
    </div>
  );
}
function Search({ search, setSearch }) {
  const ConfigProvider = useConfig();
  return (
    <div className="flex items-center p-2 justify-center w-full">
      <div className="bg-layout-bg rounded-md flex w-full h-12">
        <button
          type="button"
          className="w-8 ml-1 flex items-center justify-center ring-0 outline-none"
        >
          <MagnifyingGlassIcon className="w-5 h-5 text-page-text" />
        </button>
        <input
          type="text"
          placeholder={ConfigProvider.getTranslation("Search") + ".."}
          className="bg-layout-bg text-page-text placeholder:text-page-text rounded-md border-none outline-none w-full text-sm px-2 py-2"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  );
}

export default SearchModal;
