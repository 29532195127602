import React, { createContext, useEffect, useContext, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";

const SocketContext = createContext();

export function SocketProvider({ children }) {
  const [error, setError] = useState(false);
  const [connection, setConnection] = useState(null);
  const [connected, setConnected] = useState(false);

  const [events, setEvents] = useState({});
  const [markets, setMarkets] = useState({});

  const [eventQueue, setEventQueue] = useState([]);
  const [marketQueue, setMarketQueue] = useState([]);
  const [topMarketQueue, setTopMarketQueue] = useState([]);
  const [trackerQueue, setTrackerQueue] = useState([]);
  const [userQueue, setUserQueue] = useState([]);
  const [jackpotQueue, setJackpotQueue] = useState([]);
  const [timeQueue, setTimeQueue] = useState([]);
  const [lastWinnersQueue, setLastWinnersQueue] = useState([]);

  const clearEventMessages = (l) => {
    setEventQueue((prev) => (prev.length === l ? [] : prev));
  };
  const clearMarketMessages = (l) => {
    setMarketQueue((prev) => (prev.length === l ? [] : prev));
  };
  const clearTopMarketMessages = (l) => {
    setTopMarketQueue((prev) => (prev.length === l ? [] : prev));
  };
  const clearTrackerMessages = (l) => {
    setTrackerQueue((prev) => (prev.length === l ? [] : prev));
  };
  const clearUserMessages = (l) => {
    setUserQueue((prev) => (prev.length === l ? [] : prev));
  };
  const clearJackpotMessages = (l) => {
    setJackpotQueue((prev) => (prev.length === l ? [] : prev));
  };
  const clearTimeMessages = (l) => {
    setTimeQueue((prev) => (prev.length === l ? [] : prev));
  };
  const clearLastWinnersMessages = (l) => {
    setLastWinnersQueue((prev) => (prev.length === l ? [] : prev));
  };
  useEffect(() => {
    let url = process.env.REACT_APP_SOCKET_ENDPOINT + "/stakeHub";
    if (url === "/stakeHub") {
      url =
        "//socket." +
        window.location.hostname.split(".").slice(-2).join(".") +
        "/stakeHub";
    }
    const newConnection = new HubConnectionBuilder()
      .withUrl(url)
      .withAutomaticReconnect()
      .build();
    newConnection.on("event", (cmd, message) => {
      setEventQueue((prevQueue) => [
        ...prevQueue,
        JSON.parse(JSON.stringify({ cmd: cmd, message: message })),
      ]);
    });
    newConnection.on("market", (cmd, message) => {
      setMarketQueue((prevQueue) => [
        ...prevQueue,
        JSON.parse(JSON.stringify({ cmd: cmd, message: message })),
      ]);
    });
    newConnection.on("topMarket", (cmd, message) => {
      setTopMarketQueue((prevQueue) => [
        ...prevQueue,
        JSON.parse(JSON.stringify(message)),
      ]);
    });
    newConnection.on("tracker", (cmd, message) => {
      setTrackerQueue((prevQueue) => [
        ...prevQueue,
        JSON.parse(JSON.stringify(message)),
      ]);
    });
    newConnection.on("user", (cmd, message) => {
      setUserQueue((prevQueue) => [
        ...prevQueue,
        JSON.parse(JSON.stringify({ cmd: cmd, message: message })),
      ]);
    });
    newConnection.on("jackpot", (cmd, message) => {
      setJackpotQueue((prevQueue) => [
        ...prevQueue,
        JSON.parse(JSON.stringify({ cmd: cmd, message: message })),
      ]);
    });
    newConnection.on("syncDate", (data) => {
      setTimeQueue((prevQueue) => [...prevQueue, data]);
    });
    newConnection.on("lastWinners", (cmd, message) => {
      setLastWinnersQueue((prevQueue) => [
        ...prevQueue,
        JSON.parse(JSON.stringify({ cmd: cmd, message: message })),
      ]);
    });
    newConnection.onreconnecting(() => {
      setConnected(false);
    });

    newConnection.onclose(() => {
      setConnected(false);
      setError(true);
    });

    newConnection.onreconnected(() => {
      setConnected(true);
      connection.invoke("getServerDate");
    });
    setConnection(newConnection);

    return () => {
      setConnected(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          setConnected(true);
          connection.invoke("getServerDate");
        })
        .catch((error) => {
          setConnected(false);
        });
    }
    // eslint-disable-next-line
  }, [connection]);

  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (connected) {
      if (userId !== "") {
        subscribe(["user-" + userId]);
      }
    }
    // eslint-disable-next-line
  }, [connected]);

  const subscribe = (ids) => {
    if (connection && connection.state === "Connected") {
      connection.invoke("subscribe", ids);
    }
  };
  const unsubscribe = async (ids) => {
    if (connection && connection.state === "Connected") {
      await connection.invoke("unsubscribe", ids);
    }
  };
  const subscribeEvent = (id, from) => {
    if (connection && connection.state === "Connected") {
      if (!events.hasOwnProperty(id)) {
        subscribe([id]);
      }
      let newObj = { [from]: 1 };
      setEvents({ ...events, [id]: { ...events[id], ...newObj } });
    }
  };
  const unsubscribeEvent = (id, from) => {
    if (connection && connection.state === "Connected") {
      if (events.hasOwnProperty(id)) {
        setEvents((prevState) => {
          if (prevState[id] && prevState[id][from]) {
            delete prevState[id][from];
          }
          return prevState;
        });

        if (Object.keys(events[id]).filter((x) => x !== from).length === 0) {
          unsubscribe([id]);
          setEvents((prevState) => {
            const { [id]: removedKey, ...rest } = prevState;

            return rest;
          });
        }
      }
    }
  };
  const subscribeMarket = (id, from) => {
    if (connection && connection.state === "Connected") {
      if (!markets.hasOwnProperty(id)) {
        subscribe([id]);
      }
      let newObj = { [from]: 1 };
      setMarkets({ ...markets, [id]: { ...markets[id], ...newObj } });
    }
  };
  const unsubscribeMarket = (id, from) => {
    if (connection && connection.state === "Connected") {
      if (markets.hasOwnProperty(id)) {
        setMarkets((prevState) => {
          if (prevState[id] && prevState[id][from]) {
            delete prevState[id][from];
          }
          return prevState;
        });

        if (Object.keys(markets[id]).filter((x) => x !== from).length === 0) {
          unsubscribe([id]);
          setMarkets((prevState) => {
            const { [id]: removedKey, ...rest } = prevState;

            return rest;
          });
        }
      }
    }
  };
  const defineUserId = (id) => {
    setUserId(id);
  };
  return (
    <SocketContext.Provider
      value={{
        connection,
        error,
        connected,
        subscribe,
        unsubscribe,
        subscribeEvent,
        unsubscribeEvent,
        subscribeMarket,
        unsubscribeMarket,
        eventQueue,
        clearEventMessages,
        trackerQueue,
        clearTrackerMessages,
        marketQueue,
        clearMarketMessages,
        topMarketQueue,
        clearTopMarketMessages,
        userQueue,
        clearUserMessages,
        jackpotQueue,
        clearJackpotMessages,
        defineUserId,
        timeQueue,
        clearTimeMessages,
        lastWinnersQueue,
        clearLastWinnersMessages,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}
export const useSocket = () => {
  return useContext(SocketContext);
};
