import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import {
  ExclamationTriangleIcon,
  ArrowRightCircleIcon,
  GiftIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { usePrefences } from "../context/PrefencesContext";
import Requests from "../helpers/Requests";
import Alert from "../components/Common/Alert";
import { formatDate, addDays, getDate } from "../helpers/Helpers";
import PaginationClassic from "../components/PaginationClassic";
import Confirm from "../components/Common/Confirm";
import { useTimezone } from "../context/TimezoneContext";

function Bonuses() {
  const TimezoneProvider = useTimezone();
  const PrefencesProvider = usePrefences();
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  useEffect(() => {
    if (ConfigProvider.config.user === null) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const [formData, setFormData] = useState({
    date1: formatDate(addDays(getDate(), -7)),
    date2: formatDate(TimezoneProvider.time),
    page: 1,
    offset: 30,
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [data, setData] = useState([]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/user/getBonusesList", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            window.scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.totalItems);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          window.scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [formData.page, formData.offset]);
  const [messages, setMessages] = useState([]);
  const allStatus = {
    "-1": ConfigProvider.getTranslation("All"),
    0: ConfigProvider.getTranslation("Pending"),
    1: ConfigProvider.getTranslation("Finished"),
    2: ConfigProvider.getTranslation("Expired"),
    3: ConfigProvider.getTranslation("Cancel"),
  };
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmItem, setConfirmItem] = useState(null);

  const cancelBonus = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      let requiredFieldErros = [];

      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
        window.scrollTo(0, 0);
        setLoading(false);
      } else {
        Requests.postData("/user/cancelBonus", { _id: confirmItem._id })
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              window.scrollTo(0, 0);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);
              setTimeout(() => {
                getItems();
              }, 1000);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  return (
    <div className="flex justify-center py-3 md:py-10 px-3">
      <div className="flex flex-col w-full xl:mx-20  max-w-screen-l">
        <div className="text-center border-b border-color-2 pb-4 text-color-1 text-xl sm:text-2xl">
          {ConfigProvider.getTranslation("Bonuses")}
        </div>
        <div className="mt-3 md:mt-10">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              getItems();
            }}
          >
            <div className="lg:grid grid-cols-2 lg:grid-cols-4 shadow bg-layout-border gap-2 p-2">
              <div className="mb-2">
                <label
                  htmlFor="date1"
                  className="block text-sm font-medium leading-6"
                >
                  {ConfigProvider.getTranslation("StartDate")}:
                  <span className="text-red-500">*</span>
                </label>
                <div className="relative mt-2">
                  <input
                    id="date1"
                    name="date1"
                    type="date"
                    className="w-full placeholder:text-page-text py-1.5 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("StartDate")}
                    value={formData.date1}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="mb-2">
                <label
                  htmlFor="date2"
                  className="block text-sm font-medium leading-6"
                >
                  {ConfigProvider.getTranslation("EndDate")}:
                  <span className="text-red-500">*</span>
                </label>
                <div className="relative mt-2">
                  <input
                    id="date2"
                    name="date2"
                    type="date"
                    className="w-full placeholder:text-page-text py-1.5 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("EndDate")}
                    value={formData.date2}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium leading-6">
                  &nbsp;
                </label>
                <div className="relative mt-2">
                  <button
                    type="submit"
                    className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-1.5 w-full transition duration-150 ease-in bg-home-from-1 text-page-bg font-bold"
                    disabled={loading}
                  >
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Button.Send")}
                    </span>
                    {loading ? (
                      <span>
                        <LoadingSpinner className="w-6 h-6" />
                      </span>
                    ) : (
                      <span>
                        <ArrowRightCircleIcon className="w-6 h-6" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
          {messages.length > 0 && (
            <div className="mt-2 mb-4">
              <Alert messages={messages} setMessages={setMessages} />
            </div>
          )}
          <div className="w-full my-2">
            {loading ? (
              <div className="w-full flex items-center justify-center my-6">
                <LoadingSpinner className="text-color-3 w-12 h-12" />
              </div>
            ) : data.length === 0 ? (
              <div className="text-lg py-20 w-full flex flex-col items-center text-page-text">
                <div>
                  <ExclamationTriangleIcon className="w-24 h-24" />
                </div>
                <div className="my-3 text-white">
                  {ConfigProvider.getTranslation("NoData")}
                </div>
              </div>
            ) : (
              <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-2">
                {data.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex w-full bg-layout-bg border border-layout-border "
                    >
                      <div className="flex items-start p-2 text-xs">
                        <GiftIcon className="w-8" />
                      </div>
                      <div className="grow flex flex-col px-2">
                        <div className="text-sm flex font-semibold py-3 border-b">
                          <div className="grow">{item.name}</div>
                          <div className="text-emerald-500">
                            {`${item.bonusAmount} ${ConfigProvider.config.user.currency}`}
                          </div>
                        </div>
                        <div className="flex flex-col text-xs gap-2 pt-3 py-2">
                          <div className="flex items-center">
                            <div className="grow text-page-text">
                              {ConfigProvider.getTranslation("Status")}:
                            </div>
                            <div
                              className={`${
                                item.stat === "0"
                                  ? " bg-emerald-400/30 text-emerald-400"
                                  : item.stat === "3"
                                  ? "bg-amber-400/30 text-amber-400"
                                  : " bg-slate-700  text-slate-400"
                              } p-1 rounded text-xs leading-none`}
                            >
                              {allStatus[item.stat]}
                            </div>
                          </div>

                          <div className="flex items-center">
                            <div className="grow text-page-text">
                              {ConfigProvider.getTranslation("Date")}:
                            </div>
                            <div className="">
                              {item.date &&
                                new Intl.DateTimeFormat("default", {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  timeZone:
                                    PrefencesProvider.prefences.timezone,
                                }).format(new Date(item.date))}
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="grow text-page-text">
                              {ConfigProvider.getTranslation("EndDate")}:
                            </div>
                            <div className="">
                              {item.endDate &&
                                new Intl.DateTimeFormat("default", {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  timeZone:
                                    PrefencesProvider.prefences.timezone,
                                }).format(new Date(item.endDate))}
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="grow text-page-text">
                              {ConfigProvider.getTranslation("ReleaseWagering")}
                              :
                            </div>
                            <div className="">{item.releaseWagering}x</div>
                          </div>
                          <div className="flex items-center">
                            <div className="grow text-page-text">
                              {ConfigProvider.getTranslation("Wagered")}:
                            </div>
                            <div className="">{item.wagered}</div>
                          </div>
                          <div className="flex items-center">
                            <div className="grow text-page-text">
                              {ConfigProvider.getTranslation("WageringLeft")}:
                            </div>
                            <div className="">{item.wageringLeft}</div>
                          </div>

                          <div className="flex items-center">
                            <div className="grow text-page-text">
                              {ConfigProvider.getTranslation("BonusBalance")}:
                            </div>
                            <div className="">{item.bonusBalance}</div>
                          </div>
                          <div className="flex items-center">
                            <div className="grow text-page-text">
                              {ConfigProvider.getTranslation("BonusWinning")}:
                            </div>
                            <div className="">{item.bonusWinning}</div>
                          </div>
                          {item.stat === "0" && (
                            <button
                              type="button"
                              className="flex my-2 items-center justify-center focus:outline-none text-sm sm:text-base rounded py-1.5 w-full transition duration-150 ease-in bg-rose-500 text-page-bg font-bold"
                              disabled={loading}
                              onClick={() => {
                                setShowConfirm(true);
                                setConfirmItem(item);
                              }}
                            >
                              <span className="mr-2 uppercase">
                                {ConfigProvider.getTranslation("Cancel")}
                              </span>
                              {loading ? (
                                <span>
                                  <LoadingSpinner className="w-6 h-6" />
                                </span>
                              ) : (
                                <span>
                                  <XMarkIcon className="w-6 h-6" />
                                </span>
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
                offset={formData.offset}
              />
            </div>
          </div>
        </div>
      </div>

      {showConfirm && confirmItem ? (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={`${ConfigProvider.getTranslation("Cancel")}: ${
            confirmItem.name
          }`}
          confirm={cancelBonus}
          callback={() => {
            setShowConfirm(false);
          }}
        />
      ) : null}
    </div>
  );
}

export default Bonuses;
