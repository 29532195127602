import React from "react";
import LoadingSpinner from "../Loading/LoadingSpinner";
import { Link } from "react-router-dom";

function CasinoListProvider({ loading, data }) {
  return (
    <>
      <div className="grid grid-cols-auto-fill gap-2 mt-4">
        {loading && data.length === 0 ? (
          <>
            {(data.length === 0 ? Array(24).fill() : data).map(
              (item, index) => (
                <div
                  key={index}
                  className="p-2 rounded-lg bg-layout-bg animate-pulse border-layout-border "
                >
                  <div className="aspect-[16/11] h-24 flex items-center justify-center">
                    <LoadingSpinner className="w-6 animate-pulse grayscale opacity-20" />
                  </div>
                </div>
              )
            )}
          </>
        ) : (
          <>
            {data.map((item, index) => (
              <CasinoListProviderItem key={index} item={item} />
            ))}
          </>
        )}
      </div>
    </>
  );
}
function CasinoListProviderItem({ item }) {
  return (
    <Link
      to={`/casino/provider/${encodeURIComponent(item.name)}/${item._id}`}
      className="p-2 bg-white rounded-lg border-layout-border "
    >
      <div
        className="flex items-center justify-center font-semibold h-24"
        style={{
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${process.env.REACT_APP_CDN_ENDPOINT}/upload/img/casino/${item.icon})`,
        }}
      >
        &nbsp;
        <span className="hidden">{item.name}</span>
      </div>
    </Link>
  );
}

export default CasinoListProvider;
