import { useState, useEffect } from "react";
import { useConfig } from "../../../context/ConfigContext";
import { Link } from "react-router-dom";
import Requests from "../../../helpers/Requests";
import PageEaseInEffect from "../PageEaseInEffect";
import { useSocket } from "../../../context/SocketContext";

function LastWinnersModule({ game = undefined, offset = 10, myBets = false }) {
  const ConfigProvider = useConfig();
  const [activeTab, setActiveTab] = useState("lastWinners");

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (ConfigProvider.config.onlySports) {
      setLoading(false);
    } else {
      getItems();
    }
    // eslint-disable-next-line
  }, [activeTab]);

  const getItems = () => {
    const fetchData = async () => {
      let postData = {
        type: activeTab,
        offset: offset,
      };
      if (game) {
        postData.game = game;
      }
      const data = await Requests.postData(
        "/casino/getLastTransactions",
        postData
      );
      return data;
    };

    fetchData()
      .then((res) => {
        if (res.data.type === "success") {
          setData(res.data.data);
          if (activeTab === "lastWinners") {
            setSubscribeIds([game ? game : "all"]);
          } else {
            setSubscribeIds([]);
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const changeTab = (val) => {
    if (activeTab !== val && !loading) {
      setLoading(true);
      setActiveTab(val);
    }
  };

  const [subscribeIds, setSubscribeIds] = useState([]);
  const SocketProvider = useSocket();
  useEffect(() => {
    return () => {
      subscribeIds.forEach((element) => {
        SocketProvider.unsubscribe(["lastWinners-" + element]);
      });
    };
    // eslint-disable-next-line
  }, [subscribeIds]);
  useEffect(() => {
    if (SocketProvider.connected) {
      subscribeIds.forEach((element) => {
        SocketProvider.subscribe(["lastWinners-" + element]);
      });
    }
    // eslint-disable-next-line
  }, [SocketProvider.connected, subscribeIds]);

  const { lastWinnersQueue, clearLastWinnersMessages } = SocketProvider;
  useEffect(() => {
    if (lastWinnersQueue.length > 0) {
      const readMessages = lastWinnersQueue;
      readMessages.forEach((item) => {
        const message = JSON.parse(item.message);

        setData((prev) => {
          const newData = [message, ...prev];
          return newData.slice(0, offset);
        });
      });
      clearLastWinnersMessages(readMessages.length);
    }
    // eslint-disable-next-line
  }, [lastWinnersQueue, clearLastWinnersMessages]);

  return ConfigProvider.config.onlySports ? (
    <></>
  ) : (
    <>
      <div className="flex h-10 my-2 items-center space-x-4">
        <div className="hidden md:block md:grow">
          <div className="flex items-center text-xl font-bold">
            {ConfigProvider.getTranslation("LatestBets")}
          </div>
        </div>
        <div className="relative flex justify-around rounded-lg h-10 bg-layout-bg hide-scroll w-full max-w-96 py-0.5 px-2">
          <TabsButtons
            active={activeTab === "lastWinners"}
            text={ConfigProvider.getTranslation("LatestBets")}
            onClick={() => {
              changeTab("lastWinners");
            }}
          />
          <TabsButtons
            text={ConfigProvider.getTranslation("HighRoller")}
            active={activeTab === "topWinners"}
            onClick={() => {
              changeTab("topWinners");
            }}
          />
          {myBets && ConfigProvider.config.user !== null ? (
            <TabsButtons
              text={ConfigProvider.getTranslation("MyBets")}
              active={activeTab === "myBets"}
              onClick={() => {
                changeTab("myBets");
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <GetTab data={data} loading={loading} />
    </>
  );
}
function TabsButtons({ active, text, onClick }) {
  return (
    <button
      className={`${
        active ? "bg-page-bg " : ""
      } rounded-lg flex items-center justify-center px-4 w-full text-sm font-bold`}
      onClick={onClick}
    >
      {text}
    </button>
  );
}
function GetTab({ data, loading }) {
  const ConfigProvider = useConfig();
  const [showDetail, setShowDetail] = useState(false);
  return loading ? (
    <></>
  ) : (
    <PageEaseInEffect duration={500}>
      <div className="bg-layout-bg rounded-lg mt-3 min-h-96 flex flex-col">
        <div
          className={`w-full ${
            showDetail ? "overflow-y-scroll max-h-[36rem]" : ""
          }`}
        >
          <div className="relative overflow-hidden px-2">
            <table className="table border-separate border-spacing-x-1 border-spacing-y-6 w-full table-fixed">
              <thead>
                <tr className="">
                  <th className="md:w-[30%] sm:w-auto text-left text-page-text font-normal">
                    {ConfigProvider.getTranslation("Game")}
                  </th>
                  <th className="text-center text-page-text font-normal">
                    {ConfigProvider.getTranslation("Player")}
                  </th>
                  <th className="text-center text-page-text font-normal sm:w-32">
                    {ConfigProvider.getTranslation("Type")}
                  </th>
                  <th className="text-right text-page-text font-normal sm:w-32">
                    {ConfigProvider.getTranslation("Amount")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.slice(0, showDetail ? 1000 : 10).map((item, index) => (
                  <tr key={index} className="text-sm">
                    <td className="text-left">
                      <Link to={"/casinoGame/" + item.extra.game}>
                        <div className="truncate hover:underline text-page-text">
                          {item.extra.displayName}
                        </div>
                      </Link>
                    </td>
                    <td className="text-center">
                      <div className="truncate">{item.uname}</div>
                    </td>
                    <td className="text-center">
                      <div className="text-center">
                        <span className="overflow-hidden text-nowrap">
                          {item.type === "bet"
                            ? ConfigProvider.getTranslation("Bet")
                            : ConfigProvider.getTranslation("Winnings")}
                        </span>
                      </div>
                    </td>
                    <td
                      className={
                        item.baseAmount > 0
                          ? "text-emerald-500"
                          : item.baseAmount < 0
                          ? "text-rose-500"
                          : ""
                      }
                    >
                      <div className="flex items-center justify-end">
                        <span className="overflow-hidden text-nowrap">
                          {`${item.amount} ${
                            item.currency === "TRY"
                              ? "₺"
                              : item.currency === "EUR"
                              ? "€"
                              : item.currency
                          }`}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {data.length > 10 ? (
          <div className="w-full flex justify-center">
            <button
              className="bg-page-bg rounded-lg font-bold mx-auto gap-2 my-2 h-8 px-2"
              type="button"
              onClick={() => setShowDetail(!showDetail)}
            >
              <span className="text-page-text">
                {ConfigProvider.getTranslation(
                  !showDetail ? "LoadMore" : "LessMore"
                )}
              </span>
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </PageEaseInEffect>
  );
}

export default LastWinnersModule;
