import React from "react";
import CasinoGameItem from "./CasinoGameItem";

function CasinoGame({ loading, data, miniCol = false }) {
  return (
    <>
      <div className="w-full text-center pb-4">
        <div
          className={
            "grid grid-cols-2 gap-4 " +
            (miniCol ? "" : "md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5")
          }
        >
          {loading ? (
            <>
              {(data.length === 0 ? Array(24).fill() : data).map(
                (item, index) => (
                  <div key={index} className="w-full">
                    <div className="aspect-[16/11] bg-layout-bg animate-pulse rounded"></div>
                    <div className="truncate px-4 py-2 animate-pulse h-6"></div>
                  </div>
                )
              )}
            </>
          ) : (
            <>
              {data.map((item, index) => (
                <CasinoGameItem key={index} item={item} />
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CasinoGame;
