import React, { createContext, useContext, useEffect, useState } from "react";
import Requests from "../helpers/Requests";

const FavoritesContext = createContext();

export function FavoritesProvider({ children }) {
  const [events, setEvents] = useState({}); // for live
  const [marketTypes, setMarketTypes] = useState({});
  const [leagues, setLeagues] = useState({}); // for pre, sid-country-league
  const [btag, setBtag] = useState(""); // btag

  const toggleLeague = (lid) => {
    setLeagues((prev) => {
      if (prev[lid]) {
        const { [lid]: removedKey, ...rest } = prev;

        return rest;
      } else {
        return { ...prev, [lid]: 1 };
      }
    });
  };

  const toggleMarketType = (mt) => {
    setMarketTypes((prev) => {
      if (prev[mt]) {
        const { [mt]: removedKey, ...rest } = prev;

        return rest;
      } else {
        return { ...prev, [mt]: 1 };
      }
    });
  };
  const toggleEvents = (mt) => {
    setEvents((prev) => {
      if (prev[mt]) {
        const { [mt]: removedKey, ...rest } = prev;

        return rest;
      } else {
        return { ...prev, [mt]: 1 };
      }
    });
  };
  useEffect(() => {
    const savedData = localStorage.getItem("events");
    if (savedData) {
      setEvents(JSON.parse(savedData));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("events", JSON.stringify(events));
  }, [events]);

  useEffect(() => {
    const savedData = localStorage.getItem("leagues");
    if (savedData) {
      setLeagues(JSON.parse(savedData));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("leagues", JSON.stringify(leagues));
  }, [leagues]);
  useEffect(() => {
    const savedData = localStorage.getItem("marketTypes");
    if (savedData) {
      setMarketTypes(JSON.parse(savedData));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("marketTypes", JSON.stringify(marketTypes));
  }, [marketTypes]);

  useEffect(() => {
    const savedData = localStorage.getItem("btag");
    if (savedData) {
      setBtag(JSON.parse(savedData));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("btag", JSON.stringify(btag));
  }, [btag]);

  const [loading, setLoading] = useState(false);
  const upsertCasinoGameInteractions = (id, type, rating, del) => {
    if (!loading) {
      const fetchData = async () => {
        const data = await Requests.postData(
          "/casino/upsertCasinoGameInteractions",
          {
            _id: id,
            type: type,
            rating: rating,
            delete: del,
          }
        );
        return data;
      };

      fetchData()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  return (
    <FavoritesContext.Provider
      value={{
        leagues,
        toggleLeague,
        marketTypes,
        toggleMarketType,
        events,
        toggleEvents,
        btag,
        setBtag,
        upsertCasinoGameInteractions,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
}

export function useFavorites() {
  return useContext(FavoritesContext);
}
