import React from "react";
import { useState } from "react";
import CasinoGame from "./CasinoGame";

function TopCasinoGames({ data }) {
  const [tab, setTab] = useState(
    Object.keys(data).length > 0 ? Object.keys(data)[0] : ""
  );

  return Object.keys(data).length === 0 ? (
    <></>
  ) : (
    <>
      <div className="py-5">
        <div className="flex flex-col md:flex-row bg-layout-bg rounded-md w-full text-sm md:space-x-5">
          <div className="grow flex">
            <ul className={`grow md:px-2 truncate h-[55px]`}>
              {Object.keys(data).map((item, index) => (
                <li
                  key={index}
                  className="cursor-pointer py-2.5 px-1 float-left"
                  onClick={() => {
                    setTab(item);
                  }}
                >
                  <div
                    className={`text-page-text text-sm px-6 py-2 font-semibold rounded-md cursor-pointer hover:bg-layout-border hover:text-white ${
                      tab === item ? "bg-layout-border text-white" : ""
                    }`}
                  >
                    {data[item].name}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {tab !== "" && data[tab].games.length > 0 ? (
        <div className="flex w-full">
          <CasinoGame data={data[tab].games} loading={false} />
        </div>
      ) : null}
    </>
  );
}

export default TopCasinoGames;
