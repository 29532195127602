import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import LoadingSpinner from "../components/Common/Loading/LoadingSpinner";
import {
  ExclamationTriangleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { usePrefences } from "../context/PrefencesContext";
import { useDeviceSize } from "../context/DeviceSizeContext";
import Requests from "../helpers/Requests";
import Alert from "../components/Common/Alert";
import { formatDate, addDays, getDate } from "../helpers/Helpers";
import Dropdown from "../components/Common/FormElements/Dropdown";
import PaginationClassic from "../components/PaginationClassic";
import FinanceIcons from "../components/Finance/FinanceIcons";
import { useTimezone } from "../context/TimezoneContext";

function TransactionHistory({ onlyBonus = false }) {
  const TimezoneProvider = useTimezone();
  const prefences = usePrefences();
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  useEffect(() => {
    if (ConfigProvider.config.user === null) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  const DeviceSizeProvider = useDeviceSize();
  const deviceType = DeviceSizeProvider.deviceType;
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const [formData, setFormData] = useState({
    date1: formatDate(addDays(getDate(), -15)),
    date2: formatDate(TimezoneProvider.time),
    selectedTab: onlyBonus ? "10" : "-1",
    page: 1,
    offset: 30,
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [data, setData] = useState([]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/user/getUserTransactions", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            window.scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.totalItems);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          window.scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [formData.page, formData.offset]);

  const tabs = [
    {
      id: "-1",
      name: ConfigProvider.getTranslation("Filter.All"),
    },
    {
      id: "2",
      name: ConfigProvider.getTranslation("Casino"),
    },
    {
      id: "3",
      name: ConfigProvider.getTranslation("Payment"),
    },
  ];

  const [messages, setMessages] = useState([]);

  return (
    <div className="flex justify-center py-3 md:py-10 px-3">
      <div className="flex flex-col w-full xl:mx-20  max-w-screen-l">
        <div className="text-center border-b border-color-2 pb-4 text-color-1 text-xl sm:text-2xl">
          {onlyBonus
            ? ConfigProvider.getTranslation("BonusHistory")
            : ConfigProvider.getTranslation("TransactionHistory")}
        </div>
        <div className="mt-3 md:mt-10">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              getItems();
            }}
          >
            <div className="lg:grid grid-cols-2 lg:grid-cols-4 shadow bg-layout-border gap-2 p-2">
              {!onlyBonus && (
                <div className="mb-2 relative">
                  <Dropdown
                    items={tabs}
                    name={ConfigProvider.getTranslation("Type")}
                    selected={formData.selectedTab}
                    setSelected={(val) => {
                      setFormData({ ...formData, selectedTab: val });
                    }}
                  />
                </div>
              )}
              <div className="mb-2">
                <label
                  htmlFor="date1"
                  className="block text-sm font-medium leading-6"
                >
                  {ConfigProvider.getTranslation("StartDate")}:
                  <span className="text-red-500">*</span>
                </label>
                <div className="relative mt-2">
                  <input
                    id="date1"
                    name="date1"
                    type="date"
                    className="w-full placeholder:text-page-text py-1.5 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("StartDate")}
                    value={formData.date1}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="mb-2">
                <label
                  htmlFor="date2"
                  className="block text-sm font-medium leading-6"
                >
                  {ConfigProvider.getTranslation("EndDate")}:
                  <span className="text-red-500">*</span>
                </label>
                <div className="relative mt-2">
                  <input
                    id="date2"
                    name="date2"
                    type="date"
                    className="w-full placeholder:text-page-text py-1.5 bg-layout-bg border border-layout-border rounded-md px-4 text-left shadow-sm outline-none text-page-text sm:text-sm sm:leading-6"
                    placeholder={ConfigProvider.getTranslation("EndDate")}
                    value={formData.date2}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium leading-6">
                  &nbsp;
                </label>
                <div className="relative mt-2">
                  <button
                    type="submit"
                    className="flex items-center justify-center focus:outline-none text-sm sm:text-base rounded py-1.5 w-full transition duration-150 ease-in bg-home-from-1 text-page-bg font-bold"
                    disabled={loading}
                  >
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Button.Send")}
                    </span>
                    {loading ? (
                      <span>
                        <LoadingSpinner className="w-6 h-6" />
                      </span>
                    ) : (
                      <span>
                        <ArrowRightCircleIcon className="w-6 h-6" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
          {messages.length > 0 && (
            <div className="mt-2 mb-4">
              <Alert messages={messages} setMessages={setMessages} />
            </div>
          )}
          <div className="w-full my-2">
            {loading ? (
              <div className="w-full flex items-center justify-center my-6">
                <LoadingSpinner className="text-color-3 w-12 h-12" />
              </div>
            ) : data.length === 0 ? (
              <div className="text-lg py-20 w-full flex flex-col items-center text-page-text">
                <div>
                  <ExclamationTriangleIcon className="w-24 h-24" />
                </div>
                <div className="my-3 text-white">
                  {ConfigProvider.getTranslation("NoData")}
                </div>
              </div>
            ) : deviceType === "sm" ? (
              data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex w-full my-2 bg-layout-bg border border-layout-border "
                  >
                    <div className="flex items-center  p-2 text-xs">
                      <FinanceIcons item={item} />
                    </div>
                    <div className="grow">
                      <div className="flex p-2 text-xs">
                        <div className="grow">
                          {new Intl.DateTimeFormat("default", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            timeZone: prefences.timezone,
                          }).format(new Date(item.date))}
                        </div>
                      </div>
                      <div className="flex  text-sm items-center gap-2">
                        <div className="text-xs grow py-1.5 pl-2">
                          {item.desc}
                        </div>
                        <div
                          className={`text-xs py-1.5 pr-2 text-right ${
                            item.bonusAmount > 0
                              ? "text-green-400"
                              : item.bonusAmount < 0
                              ? "text-red-400"
                              : ""
                          }`}
                        >
                          {item.bonusAmount !== 0 && (
                            <>
                              {item.strBonusAmount + " "}
                              <span className="text-[9px]">
                                {ConfigProvider.config.user.currency}
                              </span>
                            </>
                          )}
                        </div>
                        <div
                          className={`text-xs py-1.5 pr-2 text-right ${
                            item.amount > 0
                              ? "text-green-400"
                              : item.amount < 0
                              ? "text-red-400"
                              : ""
                          }`}
                        >
                          {item.strAmount + " "}
                          <span className="text-[9px]">
                            {ConfigProvider.config.user.currency}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <table className="w-full">
                  <thead>
                    <tr className="bg-layout-bg text-sm border-b-4 text-home-from-1">
                      <th className="px-2.5 py-2 text-left">
                        {ConfigProvider.getTranslation("Date")}
                      </th>
                      <th className="px-2.5 py-2 w-full"></th>
                      <th className="px-2.5 py-2 min-w-40 text-right">
                        {ConfigProvider.getTranslation("Bonus")}
                      </th>
                      <th className="px-2.5 py-2 min-w-40 text-right">
                        {ConfigProvider.getTranslation("Amount")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr
                        key={index}
                        className="text-sm bg-layout-bg border-b-4 last:border-b-0"
                      >
                        <td className="px-2.5 py-2 text-xs text-page-text">
                          {new Intl.DateTimeFormat("default", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            timeZone: prefences.timezone,
                          }).format(new Date(item.date))}
                          <br />
                          {new Intl.DateTimeFormat("default", {
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            timeZone: prefences.timezone,
                          }).format(new Date(item.date))}
                        </td>
                        <td className="px-2.5 py-2 text-slate-300 text-xs">
                          <div className="flex items-center">
                            <div className="mr-2">
                              <FinanceIcons item={item} />
                            </div>
                            {item.desc}
                          </div>
                        </td>
                        <td
                          className={`px-2.5 py-2 text-right ${
                            item.bonusAmount > 0
                              ? "text-green-400"
                              : item.bonusAmount < 0
                              ? "text-red-400"
                              : ""
                          }`}
                        >
                          {item.bonusAmount !== 0 && (
                            <>
                              {item.strBonusAmount + " "}
                              <span className="text-[9px]">
                                {ConfigProvider.config.user.currency}
                              </span>
                            </>
                          )}
                        </td>
                        <td
                          className={`px-2.5 py-2 text-right ${
                            item.amount > 0
                              ? "text-green-400"
                              : item.amount < 0
                              ? "text-red-400"
                              : ""
                          }`}
                        >
                          {item.strAmount + " "}
                          <span className="text-[9px]">
                            {ConfigProvider.config.user.currency}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
                offset={formData.offset}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionHistory;
